/* global $, quickverleih */

quickverleih.settings = quickverleih.settings || {};
quickverleih.settings.shop = (function () {

    function init() {

        _initCategoryTree();
    }
    
    function _initCategoryTree() {

        var categoryMenu   = $('.categories-container .category__menu');
        var categoryItem   = categoryMenu.find('.has-dropdown');


        var _clickCategoryItem = function (e) {
            e.preventDefault();
            e.stopPropagation();

            var elem = $(this);

            elem.toggleClass('category__menu--active');

            if (elem.hasClass('category__menu--active') === false) {
                // class was removed from parent, remove it from children too
                elem
                    .find('.category__menu--active')
                    .removeClass('category__menu--active')
            } else {
                // remove from siblings
                elem
                    .siblings()
                    .removeClass('category__menu--active');

                // remove from siblings children
                elem
                    .siblings()
                    .find('.category__menu--active')
                    .removeClass('category__menu--active');
            }
        };

        _addNewCategory(categoryMenu);
        _editCategory(categoryMenu);
        _deleteCategory(categoryMenu);
        _enableShopSave();
        _enableSaveExtendedTimes();
        _saveWorkingHours();
        _updateOpeningTimes();
        _saveSemesterPeriod();
        _saveUserExpireDate();
        _initWorkingHoursTabs();

        categoryItem.off('click').on('click', _clickCategoryItem);
    }

    function _addNewCategory(categoryMenu) {

        var addCategoryBtn = categoryMenu.find('.category__add--content');

        var _addCategoryBtnFn = function (e) {

            e.preventDefault();
            e.stopPropagation();

            var elem     = $(this);
            var parent = elem.closest('li');
            var parentId = elem.closest('li').attr('data-id');
            var isFilterable = parent.attr('data-filterable');

            let parentCategory = parent.parent().closest('li');
            var parentCategoryIsFilterable = parentCategory.attr('data-filterable') > 0;
            let disableChangeFilterableStatus = parentCategoryIsFilterable || false;

            var popupContent = quickverleih.template.render('./includes/einstellung/templates/new_category_popup._', {
                filterable: isFilterable,
                disableChangeFilterableStatus: disableChangeFilterableStatus
            });

            console.log('Adding category for', parentId);

            var popup = openPopup(popupContent, '500px');

            var filterableInput = popup.find('input[name="filterable"]');

            var categoryName = popup.find('input[name="new_category_name"]');
            var addBtn       = popup.find('.add-category-yes');
            var cancelBtn    = popup.find('.add-category-no');

            categoryName.focus();

            addBtn.off('click').on('click', function (e) {
                e.preventDefault();

                if (categoryName.val().trim().length === 0) {

                    categoryName.addClass('input-error');
                    return;
                } else {

                    categoryName.removeClass('input-error');
                }

                let filterable = filterableInput.is(':checked');

                if (disableChangeFilterableStatus) {
                    filterable = true;
                }

                quickverleih.settings.api.createCategory(categoryName.val(), parentId, filterable, function (result) {

                    console.log('ADD', result);
                    cancelBtn.click();

                    // insert into DOM - BE
                    setTimeout(function () {

                        location.reload(true);
                    }, 200);

                });
            });
        };

        addCategoryBtn.off('click').on('click', _addCategoryBtnFn);
    }

    function _editCategory(categoryMenu) {

        var editCategoryBtn = categoryMenu.find('.edit-icon');

        let updateChildCategories = (parent, newFilterableValue) => {
            let modifiedChildCategories = [];

            parent.find("svg.filterable-icon").each((index, elem) => {
                let child = $(elem);
                var parent = elem.closest('li');
                var id     = $(parent).attr('data-id');
                modifiedChildCategories.push(id);
                $(parent).attr('data-filterable', newFilterableValue ? 1 : 0);
                child = !newFilterableValue ? child.attr("class", "icon filterable-icon hidden") : child.attr("class", "icon filterable-icon");
            });

            // Send them to server to make the db update
            quickverleih.settings.api.updateFilterableForCategories(modifiedChildCategories, newFilterableValue, (result) => {
                console.log("Update filterable for categories response: ", result);
            });
        };

        var _EditCategoryBtnFn = function (e) {

            e.preventDefault();
            e.stopPropagation();

            var elem   = $(this);
            var parent = elem.closest('li');
            var id     = parent.attr('data-id');
            var isFilterable = parent.attr('data-filterable');
            var txtInput = parent.find('> span span.menu-name');

            let parentCategory = parent.parent().closest('li');
            var parentCategoryIsFilterable = parentCategory.attr('data-filterable') > 0;
            let disableChangeFilterableStatus = parentCategoryIsFilterable || false;
            
            var popupContent = quickverleih.template.render('./includes/einstellung/templates/edit_category_popup._', {
                name: txtInput.text().trim(),
                filterable: isFilterable,
                disableChangeFilterableStatus: disableChangeFilterableStatus
            });

            console.log('Editing category for', id);

            var popup = openPopup(popupContent, '500px');

            var categoryName = popup.find('input[name="category_name"]');
            var filterableInput = popup.find('input[name="filterable"]');
            var addBtn       = popup.find('.add-category-yes');
            var cancelBtn    = popup.find('.add-category-no');

            categoryName.focus();

            addBtn.off('click').on('click', function (e) {
                e.preventDefault();

                let newCategoryName = categoryName.val();
                if (newCategoryName.trim().length === 0) {

                    categoryName.addClass('input-error');
                    return;
                } else {

                    categoryName.removeClass('input-error');
                }

                let filterable = filterableInput.is(':checked');

                if (disableChangeFilterableStatus) {
                    filterable = true;
                }

                quickverleih.settings.api.editCategory({
                    categoryName: newCategoryName,
                    filterable: filterable,
                    id: id
                }, function (result) {

                    // console.log('edit category name', result);
                    let filterableIcon = parent.find('> span svg.filterable-icon');

                    txtInput.text(newCategoryName);
                    parent.attr('data-filterable', filterable ? 1 : 0);
                    if(!filterable) {
                        filterableIcon.attr("class", "icon filterable-icon hidden");
                    } else {
                        filterableIcon.attr("class", "icon filterable-icon");
                    }

                    updateChildCategories(parent, filterable);
                    
                    parent.find('> span.text-wrap').attr('title', newCategoryName);

                    cancelBtn.click();

                    if(quickverleih.config_params.sort_shop_categories_by_name) {
                        // sort categories on this level
                        let $parentUL = parent.parent('ul');
                        $parentUL.find('> li.has-dropdown')
                            // sort by the title attribute
                            .sort(function(a, b) { return $(a).find('span.text-wrap').attr('title').localeCompare($(b).find('span.text-wrap').attr('title')); })
                            .prependTo($parentUL);
                    }
                });
            });
        };

        editCategoryBtn.off('click').on('click', _EditCategoryBtnFn);
    }

    function _deleteCategory(categoryMenu) {

        var deleteCategoryBtn = categoryMenu.find('.delete-icon');

        var _DeleteCategoryBtnFn = function (e) {

            e.preventDefault();
            e.stopPropagation();

            var elem   = $(this);
            var parent = elem.closest('li');
            var id     = parent.attr('data-id');

            var txtInput = parent.find('> span span.menu-name');

            var popupContent = quickverleih.template.render('./includes/einstellung/templates/delete_category_popup._', {
                name: txtInput.text().trim()
            });

            console.log('Deleting category for', id);

            var popup = openPopup(popupContent, '500px');

            var categoryName = popup.find('input[name="category_name"]');
            var addBtn       = popup.find('.add-category-yes');
            var cancelBtn    = popup.find('.add-category-no');

            categoryName.focus();

            addBtn.off('click').on('click', function (e) {
                e.preventDefault();

                quickverleih.settings.api.deleteCategory(id, function (result) {

                    console.log('Delete', result);

                    parent.remove();

                    cancelBtn.click();
                });
            });
        };

        deleteCategoryBtn.off('click').on('click', _DeleteCategoryBtnFn);
    }



    function _enableShopSave (){

        $('input[type = radio][class = shopRadioSettings]').change(function() {

            let selectedDatepicker = $("input[type='radio'][name='rentalDateType']:checked").val();
            let selectedLoginType = $("input[type='radio'][name='loginType']:checked").val();

            quickverleih.settings.api.changeSettings(selectedDatepicker, selectedLoginType)
        });
    }

    function _enableSaveExtendedTimes (){
        $('input[type = checkbox][class = extended-times-checkbox]').change(function() {
            let extendedTimes = $("input[type='checkbox'][name='extended_times']").is(':checked') ? 1 : 0;
            // Change the text of the working hours table
            if(extendedTimes) {
                // Prepare the tables for extended times
                let $pickupHoursTitle = $('.pickup-hours-title').text("Öffnungszeiten");
                let $dropoffHoursTitle = $('.dropoff-hours-title').text("Erweiterte Rückgabezeiten mit Schlüssel");

                let container = $pickupHoursTitle.closest('.title-container').siblings('.working_hours');
                
                container.find('.each-day').each(function () {
                    let inputOf = $(this).find('.timeInput');

                    let von1 = inputOf.eq(0);
                    let bis1 = inputOf.eq(1);

                    if(!von1.prop('disabled')) {
                        updateExtendedTimes(von1);
                    }
                    if(!bis1.prop('disabled')) {
                        updateExtendedTimes(bis1);
                    }
                });
            } else {
                // Prepare the tables for dropoff times
                let $pickupHoursTitle = $('.pickup-hours-title').text("AUSGABEZEITEN");
                let $dropoffHoursTitle = $('.dropoff-hours-title').text("RÜCKGABEZEITEN");

                let container = $pickupHoursTitle.closest('.title-container').siblings('.working_hours');
                container.find('.each-day').each(function () {
                    let inputOf = $(this).find('.timeInput');

                    let von1 = inputOf.eq(0);
                    
                    deactivateExtendedTimes(von1);
                });
            }

            // Moved the save on the save working hours button
            // quickverleih.settings.api.changeExtendedTimes(extendedTimes);
        });
    }

    var deactivateExtendedTimes = (input) => {
        let enableAllOptions = (select) => {
            // Enable all options
            select.find('option[disabled]').prop('disabled', false);
        };

        let isPickupSelect = $(input).hasClass('pickup-select');
        let extendedTimes = $("input[type='checkbox'][name='extended_times']").is(':checked') ? 1 : 0;
        
        if(isPickupSelect && extendedTimes) {
            let changedDay = $(input).data("day");
            
            // Change the disabled options for extended times bis
            let returnSection = $(input).closest('.alldays').siblings('.alldays');

            // For first intervals remove the disabled hours
            let returnBis1Select = returnSection.find('#'+changedDay+'Bis1');
            enableAllOptions(returnBis1Select);
            let returnVon1Select = returnSection.find('#'+changedDay+'Von1');
            enableAllOptions(returnVon1Select);

            // For second interval set the enabled/disabled based on the first interval
            // In case the day is disabled keep the second intervals disabled aswell
            let returnBis2Select = returnSection.find('#'+changedDay+'Bis2');
            returnBis2Select.prop('disabled', returnBis1Select.prop('disabled'));
            let returnVon2Select = returnSection.find('#'+changedDay+'Von2');
            returnVon2Select.prop('disabled', returnBis1Select.prop('disabled'));
        }
    };

    function fromG() {


        $('.category__add--trigger-input').on('click', function (e) {

            $('.category__add--trigger-input').removeClass('category__add--input-activated');

            $(this).addClass('category__add--input-activated');

            $(this).find('.category__add--input').focus();
        });

        $('.category__menu a:not(:only-child)')
            .parent()
            .addClass('has-dropdown');

        $('.category__menu').on('click', function (e) {

            e.stopPropagation();

            var target = $(e.target).closest('li');

            if (target.hasClass('category__add')) {

                target.siblings().removeClass('category__menu--active');

            } else if (target.hasClass('has-dropdown')) {

                target
                    .toggleClass('category__menu--active')
                    .siblings()
                    .removeClass('category__menu--active');

                target
                    .siblings()
                    .find('.category__add--input-activated')
                    .removeClass('category__add--input-activated');

            } else {

                target
                    .siblings()
                    .find('.category__add--input-activated')
                    .removeClass('category__add--input-activated');

                target
                    .siblings()
                    .removeClass('category__menu--active');

            }


        });

        //dropdown toggle for article cats
        $(document)
            .on('click', function(e) {

                if (!$(e.target).parents('.category__menu').length) {

                    $('.category__add--trigger-input').removeClass('category__add--input-activated');

                    $('.category__menu--active').removeClass('category__menu--active');
                }

            });
    }

    function _saveWorkingHours (){

        $('#shopHoursSubmit').on('click',function () {

            let workingHoursClassesPickup = [];
            let workingHoursClassesDropoff = [];
            let workingHoursNoClassesPickup = [];
            let workingHoursNoClassesDropoff = [];

            let getDataForDay = (that) => {
                let day = $(that).attr('data-day');

                let dayData = {
                    'day' : day,
                    'von1': false,
                    'bis1': false,
                    'von2': false,
                    'bis2': false
                };

                let von1 = $(that).find('#'+day+'Von1').val();
                let bis1 = $(that).find('#'+day+'Bis1').val();

                let von2 = $(that).find('#'+day+'Von2').val();
                let bis2 = $(that).find('#'+day+'Bis2').val();

                if ($(that).find(':checkbox').is(':checked')) {
                    dayData = {
                        'day' : day,
                        'von1': von1,
                        'bis1': bis1,
                        'von2': von2,
                        'bis2': bis2
                    };
                }

                return dayData;
            }

            $(this).siblings('.alldaysClasses').children('.alldaysClassesPickupContainer').children('.alldaysClassesPickup').find('.each-day').each(function () {
                workingHoursClassesPickup.push(getDataForDay(this));
            });

            $(this).siblings('.alldaysClasses').children('.alldaysClassesDropoffContainer').children('.alldaysClassesDropoff').find('.each-day').each(function () {
                workingHoursClassesDropoff.push(getDataForDay(this));
            });

            $(this).siblings('.alldaysNoClasses').children('.alldaysNoClassesPickupContainer').children('.alldaysNoClassesPickup').find('.each-day').each(function () {
                workingHoursNoClassesPickup.push(getDataForDay(this));
            });

            $(this).siblings('.alldaysNoClasses').children('.alldaysNoClassesDropoffContainer').children('.alldaysNoClassesDropoff').find('.each-day').each(function () {
                workingHoursNoClassesDropoff.push(getDataForDay(this));
            });

            let workingHours = {
                "classes": {
                    "pickup": workingHoursClassesPickup,
                    "dropoff": workingHoursClassesDropoff,
                },
                "no_classes": {
                    "pickup": workingHoursNoClassesPickup,
                    "dropoff": workingHoursNoClassesDropoff,
                }
            };

            // Moved the extendedTimes saving here before saving the working hours
            let extendedTimes = $("input[type='checkbox'][name='extended_times']").is(':checked') ? 1 : 0;
            quickverleih.settings.api.changeExtendedTimes(extendedTimes);

            quickverleih.settings.api.changeWorkingHours(workingHours, function () {
                location.reload();
            });
        })
    }

    var updateExtendedTimes = (input) => {
        let disableSmallerOptions = (select, minValue) => {
            // If the selected value is going to be disabled and the select is not disabled -> set the value to the largest available
            if(select.val() < minValue && !select.prop('disabled')) {
                select.val(minValue);
            }

            // Enable all options
            select.find('option[disabled]').prop('disabled', false);
            // Disable smaller options
            select.find('option').each(function() {
                if($(this).val() < minValue) {
                    $(this).prop('disabled', true); 
                }
            });
        };

        let disableLargerOptions = (select, maxValue) => {
            // If the selected value is going to be disabled and the select is not disabled -> set the value to the smallest available
            if(select.val() > maxValue && !select.prop('disabled')) {
                select.val(maxValue);
            }

            // Enable all options
            select.find('option[disabled]').prop('disabled', false);
            // Disable larger options
            select.find('option').each(function() {
                if($(this).val() > maxValue) {
                    $(this).prop('disabled', true); 
                }
            });
        };
        
        let inputOf = $(input).closest('.dayPeriods').find('.timeInput');

        let valVon1 = inputOf.eq(0).val();
        let valBis1 = inputOf.eq(1).val();
        let valVon2 = inputOf.eq(2).val();
        let valBis2 = inputOf.eq(3).val();

        let isPickupSelect = $(input).hasClass('pickup-select');
        let extendedTimes = $("input[type='checkbox'][name='extended_times']").is(':checked') ? 1 : 0;

        let isPickupSelectVon1 = $(input).hasClass('von1');
        let isPickupSelectBis1 = $(input).hasClass('bis1');
        let isPickupSelectVon2 = $(input).hasClass('von2');
        let isPickupSelectBis2 = $(input).hasClass('bis2');

        let selectedValue = $(input).val();
        if (isPickupSelectBis1 || isPickupSelectBis2) {
            selectedValue = (valBis2 !== '' && valBis1 < valBis2 ? valBis2 : valBis1);
        }

        if (isPickupSelectVon1 || isPickupSelectVon2) {
            selectedValue = (valVon2 !== '' && valVon1 > valVon2 ? valVon2 : valVon1);
        }

        if(isPickupSelect && extendedTimes) {
            // Change the disabled options for extended times bis
            let returnSection = $(input).closest('.alldays').siblings('.alldays');
            let changedDay = $(input).data("day");
            
            // For bis selects disable the values smaller then the largest value of the pickup bis times
            if(isPickupSelectBis1 || isPickupSelectBis2) {
                let returnBisSelect = returnSection.find('#'+changedDay+'Bis1');
                disableSmallerOptions(returnBisSelect, selectedValue);
            } else {// For von selects disable the values larger then the largest value of the pickup von times
                let returnVonSelect = returnSection.find('#'+changedDay+'Von1');
                disableLargerOptions(returnVonSelect, selectedValue);
            }

            // Disable second interval inputs and set selected valu to "" -> no selection
            let von2 = returnSection.find('#'+changedDay+'Von2');
            let bis2 = returnSection.find('#'+changedDay+'Bis2');

            von2.prop('disabled', true);
            bis2.prop('disabled', true);
            von2.find('option').eq(0).prop('selected', true);
            bis2.find('option').eq(0).prop('selected', true);
        }
    };

    function _updateOpeningTimes () {

        var enable_inputs_for_opening_day = (elemCheckbox) => {
            const $this = $(elemCheckbox);
            const $timeInputs = $this.closest('.each-day').find('.timeInput');

            if(elemCheckbox.checked) {
                let isExtendedTime = $this.hasClass('extended-times');
                if(isExtendedTime) {
                    $timeInputs.eq(0).prop('disabled', false);
                    $timeInputs.eq(1).prop('disabled', false);
                } else {
                    $timeInputs.removeAttr('disabled');
                }

                $timeInputs.eq(0).find('option[value="09:00"]').prop('selected', true);
                $timeInputs.eq(1).find('option[value="09:30"]').prop('selected', true);
            } else {

                $timeInputs
                    .attr('disabled', 'disabled')
                    .removeClass('warning-border')
                    .removeClass('all-warning-border');

                $timeInputs.each(function () {
                    $(this).find('option').eq(0).prop('selected', true);
                });
            }
        }

        // enable/disable time inputs based on the checbox, for all 4 sections
         $('.day-checkbox-classesPickup, .day-checkbox-classesDropoff, .day-checkbox-noClassesPickup, .day-checkbox-noClassesDropoff').change(function(e) {
            enable_inputs_for_opening_day(this);
        });

        /**
         * Select Option for select Input
         */

        $('.timeInput').each(function () {

            let selectedOpt = $(this).attr('data-value');
            $(this).find('option[value = "' + selectedOpt + '"]').attr('selected','selected')
        });

        $('.timeInput').change(function () {

            let inputOf = $(this).closest('.dayPeriods').find('.timeInput');

            let pVon1 = moment(inputOf.eq(0).val(), 'HH:mm', true);
            let pBis1 = moment(inputOf.eq(1).val(), 'HH:mm', true);
            let pVon2 = moment(inputOf.eq(2).val(), 'HH:mm', true);
            let pBis2 = moment(inputOf.eq(3).val(), 'HH:mm', true);

            updateExtendedTimes(this);

            let enable_submit_hours = true;

            if(!pVon1.isValid() || !pBis1.isValid() ||
                pVon1.isAfter(pBis1) || pVon1.isSame(pBis1)) {

                $(this).closest('.dayPeriods').find('.timeInput').eq(0).addClass('warning-border');
                $(this).closest('.dayPeriods').find('.timeInput').eq(1).addClass('warning-border');

                enable_submit_hours = false;

            } else {

                $(this).closest('.dayPeriods').find('.timeInput').eq(0).removeClass('warning-border');
                $(this).closest('.dayPeriods').find('.timeInput').eq(1).removeClass('warning-border');
            }

            // if both are not valid then ignore the 2nd line
            if(!pVon2.isValid() && !pBis2.isValid()) {
                $(this).closest('.dayPeriods').find('.timeInput').eq(2).removeClass('warning-border');
                $(this).closest('.dayPeriods').find('.timeInput').eq(3).removeClass('warning-border');
            } else {
                if(!pVon2.isValid() || !pBis2.isValid() ||
                    pVon2.isAfter(pBis2) || pVon2.isSame(pBis2)) {

                    $(this).closest('.dayPeriods').find('.timeInput').eq(2).addClass('warning-border');
                    $(this).closest('.dayPeriods').find('.timeInput').eq(3).addClass('warning-border');

                    enable_submit_hours = false;

                } else {

                    $(this).closest('.dayPeriods').find('.timeInput').eq(2).removeClass('warning-border');
                    $(this).closest('.dayPeriods').find('.timeInput').eq(3).removeClass('warning-border');
                }

                if (pVon2.isBefore(pBis1) && inputOf.eq(3).val() !== '00:00' || pVon2.isSame(pBis1) ) {

                    $(this).closest('.dayPeriods').find('.timeInput').addClass('all-warning-border');

                    enable_submit_hours = false;

                } else {
                    $(this).closest('.dayPeriods').find('.timeInput').removeClass('all-warning-border');
                }
            }

            if(enable_submit_hours) {
                enableSaveButton('#shopHoursSubmit');
            } else {
                disableSaveButton('#shopHoursSubmit');
            }            
        });        
    }

    function parseDate(input) {
        var parts = input.match(/(\d+)/g);
        return new Date(parts[2], parts[1]-1, parts[0]);
    }

    function _saveSemesterPeriod () {
        const lazy_change_semester_dates = _.debounce(semester_dates_changed, 500);

        $('.semester-container').find('input').keyup(lazy_change_semester_dates);

        let areBothStartAndEndDateFilled = ($input) => {
            let $sibling = $input.parent(".sem-period").siblings(".sem-period").find(".sem-input");

            if($input.val() == "") {
                if($sibling.val() == "") {
                    // Uncomment to mark both fields as invalid
                    // $input.css('border-color','#eaeaea');
                    // $input.removeClass('warning-date').addClass('valid');

                    $sibling.css('border-color','#eaeaea');
                    $sibling.removeClass('warning-date').addClass('valid');
                    return true;
                } else {
                    // Uncomment to mark both fields as invalid
                    // $input.css('border-color','#eb547c');
                    // $input.addClass('warning-date').removeClass('valid');

                    $sibling.css('border-color','#eb547c');
                    $sibling.addClass('warning-date').removeClass('valid');
                    return false;
                }
            } else {
                if($sibling.val() == "") {
                    // Uncomment to mark both fields as invalid
                    // $input.css('border-color','#eb547c');
                    // $input.addClass('warning-date').removeClass('valid');

                    $sibling.css('border-color','#eb547c');
                    $sibling.addClass('warning-date').removeClass('valid');
                    return false;
                } else {
                    // Uncomment to mark both fields as invalid
                    // $input.css('border-color','#eaeaea');
                    // $input.removeClass('warning-date').addClass('valid');

                    $sibling.css('border-color','#eaeaea');
                    $sibling.removeClass('warning-date').addClass('valid');
                    return true;
                }
            }
        };

        let setMarkingsForBeforeAfterValidation = ($inputBefore, $input, momDateForInputBefore, momDateForInput) => {
            let isValid = true;

            if(momDateForInputBefore.isValid() && momDateForInput.isValid()) {
                if(momDateForInputBefore.isBefore(momDateForInput)) {
                    isValid = true;

                    $input.css('border-color','#eaeaea');
                    $input.removeClass('warning-date').addClass('valid');

                    $inputBefore.css('border-color','#eaeaea');
                    $inputBefore.removeClass('warning-date').addClass('valid');
                } else {
                    isValid = false;
                    
                    $input.css('border-color','#eb547c');
                    $input.addClass('warning-date').removeClass('valid');

                    $inputBefore.css('border-color','#eb547c');
                    $inputBefore.addClass('warning-date').removeClass('valid');
                }
            }

            return isValid;
        }

        let checkIntervalsOrder = ($input) => {
            let d = new Date();
            let currentYear = '.'+d.getFullYear().toString();
            let nextYear = '.'+(d.getFullYear() + 1).toString();

            let inputValue = $input.val();

            let $classesSummerFromInput = $('.classes-summer-from');
            let $classesSummerToInput = $('.classes-summer-to');
            let $noClassesSummerFromInput = $('.no-classes-summer-from');
            let $noClassesSummerToInput = $('.no-classes-summer-to');

            let $classesWinterFromInput = $('.classes-winter-from');
            let $classesWinterToInput = $('.classes-winter-to');
            let $noClassesWinterFromInput = $('.no-classes-winter-from');
            let $noClassesWinterToInput = $('.no-classes-winter-to');

            if($input.hasClass('classes-summer-from')) {
                return true;
            } else if($input.hasClass('classes-summer-to')) {
                let dateForInput = inputValue+currentYear;
                let momDateForInput = moment(dateForInput, 'DD.MM.YYYY', true);
                
                // Get previous input - classes summer from - date
                let $inputBefore = $classesSummerFromInput;
                let dateForInputBefore = $inputBefore.val()+currentYear;
                let momDateForInputBefore = moment(dateForInputBefore, 'DD.MM.YYYY', true);

                return setMarkingsForBeforeAfterValidation($inputBefore, $input, momDateForInputBefore, momDateForInput);
            } else if($input.hasClass('no-classes-summer-from')) {
                let dateForInput = inputValue+currentYear;
                let momDateForInput = moment(dateForInput, 'DD.MM.YYYY', true);

                // Get previous input - classes summer from - date
                let $inputBefore = $classesSummerToInput;
                let dateForInputBefore = $inputBefore.val()+currentYear;
                let momDateForInputBefore = moment(dateForInputBefore, 'DD.MM.YYYY', true);

                return setMarkingsForBeforeAfterValidation($inputBefore, $input, momDateForInputBefore, momDateForInput);
            } else if($input.hasClass('no-classes-summer-to')) {
                let dateForInput = inputValue+currentYear;
                let momDateForInput = moment(dateForInput, 'DD.MM.YYYY', true);

                // Get previous input - classes summer from - date
                let $inputBefore = $noClassesSummerFromInput;
                let dateForInputBefore = $inputBefore.val()+currentYear;
                let momDateForInputBefore = moment(dateForInputBefore, 'DD.MM.YYYY', true);

                return setMarkingsForBeforeAfterValidation($inputBefore, $input, momDateForInputBefore, momDateForInput);
            } else if($input.hasClass('classes-winter-from')) {
                let dateForInput = inputValue+currentYear;
                let momDateForInput = moment(dateForInput, 'DD.MM.YYYY', true);

                // Get previous input - classes summer from - date
                let $inputBefore = $noClassesSummerToInput;
                let dateForInputBefore = $inputBefore.val()+currentYear;
                let momDateForInputBefore = moment(dateForInputBefore, 'DD.MM.YYYY', true);

                return setMarkingsForBeforeAfterValidation($inputBefore, $input, momDateForInputBefore, momDateForInput);
            } else if($input.hasClass('classes-winter-to')) {
                let dateForInput = inputValue+nextYear;
                let momDateForInput = moment(dateForInput, 'DD.MM.YYYY', true);

                // Get previous input - classes summer from - date
                let $inputBefore = $classesWinterFromInput;
                let dateForInputBefore = $inputBefore.val()+currentYear;
                let momDateForInputBefore = moment(dateForInputBefore, 'DD.MM.YYYY', true);

                return setMarkingsForBeforeAfterValidation($inputBefore, $input, momDateForInputBefore, momDateForInput);
            } else if($input.hasClass('no-classes-winter-from')) {
                let dateForInput = inputValue+nextYear;
                let momDateForInput = moment(dateForInput, 'DD.MM.YYYY', true);

                // Get previous input - classes summer from - date
                let $inputBefore = $classesWinterToInput;
                let dateForInputBefore = $inputBefore.val()+nextYear;
                let momDateForInputBefore = moment(dateForInputBefore, 'DD.MM.YYYY', true);

                return setMarkingsForBeforeAfterValidation($inputBefore, $input, momDateForInputBefore, momDateForInput);
            } else if($input.hasClass('no-classes-winter-to')) {
                let dateForInput = inputValue+nextYear;
                let momDateForInput = moment(dateForInput, 'DD.MM.YYYY', true);

                // Get previous input - classes summer from - date
                let $inputBefore = $noClassesWinterFromInput;
                let dateForInputBefore = $inputBefore.val()+nextYear;
                let momDateForInputBefore = moment(dateForInputBefore, 'DD.MM.YYYY', true);

                return setMarkingsForBeforeAfterValidation($inputBefore, $input, momDateForInputBefore, momDateForInput);
            } else {
                return true;
            }
        };

        function semester_dates_changed () {
            let isValid = true; 
            let d = new Date();
            let currentYear = '.'+d.getFullYear().toString();
            let nextYear = '.'+(d.getFullYear() + 1).toString();

            let input = $(this).val();
            let date = input+currentYear;
            let momDate = moment(date, 'DD.MM.YYYY', true);

            if (momDate.isValid() || input === ""){
                $(this).css('border-color','#eaeaea');
                $(this).removeClass('warning-date').addClass('valid');
            } else {
                isValid = false;
                $(this).css('border-color','#eb547c');
                $(this).addClass('warning-date').removeClass('valid');
            }

            if(isValid && areBothStartAndEndDateFilled($(this)) && checkIntervalsOrder($(this))) {
                let allinputs = $('.semester-container').find('input').length;
                let valid = $('.semester-container').find(':input.valid').length;

                if (allinputs === valid){

                    let summer_start_date = $('#summervon').val() === "" ? "" : convertStringDate(parseDate($('#summervon').val()+currentYear),'Y-m-d');
                    let summer_end_date   = $('#summerbis').val() === "" ? "" : convertStringDate(parseDate($('#summerbis').val()+currentYear),'Y-m-d');
                    let winter_start_date = $('#wintervon').val() === "" ? "" : convertStringDate(parseDate($('#wintervon').val()+currentYear),'Y-m-d');
                    let winter_end_date   = $('#winterbis').val() === "" ? "" : convertStringDate(parseDate($('#winterbis').val()+nextYear),'Y-m-d');

                    let no_classes_summer_start_date = $('#noclasses_summervon').val() === "" ? "" : convertStringDate(parseDate($('#noclasses_summervon').val()+currentYear),'Y-m-d');
                    let no_classes_summer_end_date   = $('#noclasses_summerbis').val() === "" ? "" : convertStringDate(parseDate($('#noclasses_summerbis').val()+currentYear),'Y-m-d');
                    let no_classes_winter_start_date = $('#noclasses_wintervon').val() === "" ? "" : convertStringDate(parseDate($('#noclasses_wintervon').val()+nextYear),'Y-m-d');
                    let no_classes_winter_end_date   = $('#noclasses_winterbis').val() === "" ? "" : convertStringDate(parseDate($('#noclasses_winterbis').val()+nextYear),'Y-m-d');

                    if (new Date(summer_start_date) < new Date(summer_end_date) &&  new Date(winter_start_date) < new Date(winter_end_date) ) {
                        quickverleih.settings.api.changeSemesters(summer_start_date, summer_end_date ,winter_start_date, winter_end_date,
                                                            no_classes_summer_start_date, no_classes_summer_end_date, no_classes_winter_start_date, no_classes_winter_end_date);
                    }
                }
            }
        }
    }

    function _saveUserExpireDate() {

        let typingTimer;
        let doneTypingInterval = 500;
        let that;

        $('.user-exp-container').find('input').keyup(function(){
            clearTimeout(typingTimer);
            if ($(this).val()) {
                that = this;
                typingTimer = setTimeout(doneT, doneTypingInterval);
            }
        });

        function doneT () {

            let d = new Date();
            let currentYear = '.'+d.getFullYear().toString();

            let input = $(that).val();
            let date = input+currentYear;
            let momDate = moment(date, 'DD.MM.YYYY', true);

            if (momDate.isValid()){
                $(that).css('border-color','#eaeaea');
                $(that).removeClass('warning-date').addClass('valid');
            } else {
                $(that).css('border-color','#eb547c');
                $(that).addClass('warning-date').removeClass('valid');
            }

            let allinputs = $('.user-exp-container').find('input').length;
            let valid = $('.user-exp-container').find(':input.valid').length;

            if (allinputs === valid){

                let sem1ExpDate = convertStringDate(parseDate($('#sem1').val()+currentYear),'Y-m-d');
                let sem2ExpDate = convertStringDate(parseDate($('#sem2').val()+currentYear),'Y-m-d');

                quickverleih.settings.api.changeUserExpDates(sem1ExpDate, sem2ExpDate);

            }
        }
    }

    function _initWorkingHoursTabs (){

        $('.alldaysNoClasses').hide();

        $('#classese_tab').off('click').on('click', function(e) {
            $('#classes_schedule').show();
            $('#no_classes_schedule').hide();
            $('#classese_tab').addClass('selected');
            $('#no_classese_tab').removeClass('selected');
        });

        $('#no_classese_tab').off('click').on('click', function(e) {
            $('#classes_schedule').hide();
            $('#no_classes_schedule').show();
            $('#classese_tab').removeClass('selected');
            $('#no_classese_tab').addClass('selected');
        });
    }
    
    return {
        init: init
    };
})();