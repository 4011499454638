/* global $, ENABLE_HOURS, moment, quickverleih */

$(document).ready(function () {

    /* CUSTOMER */
    /*
    * Adds a customer into contract from popup
    */
    $(document).on('click', '.add-customer', function () {
        var customerId = $(this).attr('id').split('_');
        customerId = customerId[1];
        // console.log(customerId);

        if (_callback_selected_customer === null) {
            quickverleih.contract.add_customer_to_contract(customerId);
        } else {
            _callback_selected_customer(customerId);
            closePopup();
        }
    });

    $(document).on('click', '.add-article', function() {
		closePopup();
		
		var articleId = $(this).attr('id').split('_');
		articleId = articleId[1];

		if(_article_ids_already_in_contract !== null && _article_ids_already_in_contract.includes(articleId)) {
			// console.log('article already in contract');
			return;
		}

		// get rental interval
		var interval = quickverleih.storage.get_inteval_for_insert_article();

		addArticleInContract(articleId, interval.start_date, interval.start_time, interval.end_date, interval.end_time);
	});

    function addArticleInContract(articleId, start_date, start_time, end_date, end_time) {
        if (_callback_selected_article !== null) {
            _callback_selected_article(articleId, `${start_date} ${start_time}`, `${end_date} ${end_time}`);
            return;
        }
    
        console.log("_callback_selected_article not defined");
    }

    (function initReservationContractTable() {

        var contractTable = $('#contractsTable');
        var table = contractTable.DataTable();

        // order current loans table after 'nummer'
        table.column('0').order('desc').draw();

        $('#contractsTable_filter input').off().on('keypress', function (e) {

            let key = e.which || e.keyCode;
            if (key === 13) {

                let init = true;
                let type = $('#contractsTable').attr('data-type');

                $('#ContractsTableWrapper').addClass('loading');

                $.ajax({
                    type: "GET",
                    url: "includes/vertraege/ajax/contract_search.php?" + "&contract_filter=" + this.value + "&contract_init=" + init + "&contract_type=" + type,
                    success: function (data) {

                        let table = $('#contractsTable').DataTable();

                        table.clear();

                        $(data).each(function () {

                            if ($(this).is('tr')) {

                                table.row.add($(this));
                            }
                        });

                        table.draw();

                        setTimeout(function () {

                            $('#ContractsTableWrapper').removeClass('loading');
                        }, 200);
                    }
                });
            }
        });
    })();
});

/*
* Opens a popup with customers table

    callback_selected_customer - if this is specified the selected customer id is passed to the callback (new logic)
    if not specified - the old app logic is executed
*/
let _callback_selected_customer = null
function openCustomerTable(articleTenantId = null, callback_selected_customer = null) {
    if ($("#openCustomerTable").attr('disabled') == 'disabled') {
        return;
    }

    _articleTenantId = articleTenantId;
    _callback_selected_customer = callback_selected_customer;

    $.ajax({
        url: 'includes/vertraege/ajax/ajaxContract.php',
        type: 'POST',
        data: {
            articleTenantId: _articleTenantId,
            method: 'openCustomerTable'
        },
        success: function (data) {
            var html = '<div class="dash-block-header"> <div class="message-header-title text-uppercase">';
            html += 'Ausleiher Auswählen';
            html += '</div></div>';
            html += data;
            html += '<br><br>';

            openPopup(html, '800px');
            var dataTablesOptions = setDataTablesOptions($('#CustomersTableWrapper').find('.data-table-block'));
            $('#contractCustomerTable').DataTable(dataTablesOptions);

            $('#contractCustomerTable_filter').find('input').on('keypress', function (e) {
                let key = e.which || e.keyCode;
                if (key === 13) {

                    $('#CustomersTableWrapper').addClass('loading');

                    $.ajax({
                        type: 'POST',
                        data: {
                            articleTenantId: _articleTenantId,
                            customer_filter: this.value
                        },
                        url: "includes/vertraege/ajax/ContractCustomersTableRowsView.php",
                        success: function (data) {

                            let table = $('#contractCustomerTable').DataTable();

                            table.clear();

                            $(data).each(function () {

                                if ($(this).is('tr')) {

                                    table.row.add($(this));
                                }
                            });

                            table.draw();

                            setTimeout(function () {

                                $('#CustomersTableWrapper').removeClass('loading');
                            }, 200);
                        }
                    });

                }

            });

        }
    });

}
/*
* Opens a popup with article table
*/
let _article_ids_already_in_contract = null;
let _customerTenantId = null;
let _articleTenantId = null;
let _callback_selected_article = null
function openArticleTable(customerTenantId, articleTenantId = null, article_ids_in_contract = null, callback_selected_article = null) {
    _article_ids_already_in_contract = article_ids_in_contract;
    _customerTenantId = customerTenantId;
    _articleTenantId = articleTenantId;
    _callback_selected_article = callback_selected_article;

    // get start - end dates
    var interval = getIntervalForAddArticlePopup();

    $.ajax({
        url: 'includes/vertraege/ajax/ajaxContract.php',
        type: 'POST',
        data: {
            customerTenantId: _customerTenantId,
            articleTenantId: _articleTenantId,
            start_date: interval.start_date,
            start_time: interval.start_time,
            end_date: interval.end_date,
            end_time: interval.end_time,
            method: 'openArticleTable'
        },
        success: function (data) {
            var html = '<div class="dash-block-header"> <div class="message-header-title text-uppercase">';
            html += 'Artikel Auswählen';
            html += '</div></div>';
            html += data;
            html += '<br><br>';

            // show header
            openPopup(html, '1200px');

            // start the categories select
            quickverleih.app.init_categories_select(_customerTenantId, _articleTenantId, interval.start_date, interval.start_time, interval.end_date, interval.end_time, "", false, 0);

            var dataTablesOptions = setDataTablesOptions($('#ArticlesTableWrapper').find('.data-table-block'));
            $('#contractArticleTable').DataTable(dataTablesOptions);

            // popupArticleTableReload(_customerTenantId, _articleTenantId, interval);
        }
    });
}

// grays out articles that are already in contract
function grayOutArticlesInContract() {
    if (_article_ids_already_in_contract !== null) {

        for (const article_id of _article_ids_already_in_contract) {

            $(`#contractArticleTable #addArticle_${article_id}.add-article`)
                .css({ 'opacity': '0.25', 'cursor': 'default' })
                .attr('title', 'Artikel bereits hinzugefügt');
        }
    }
}

/*
* Repopulate popup article table
* specFilter is a json object containing key and value, used to filter articles by a specification (spezi) field
*/
function popupArticleTableReload(customerTenantId, articleTenantId, interval = false, filter, specFilter = false, locationId = 0, category = 0) {

    setTimeout(function () {
        $('#ArticlesTableWrapper').removeClass('loading');
    }, 200);

    if (typeof filter === 'undefined' || filter === null) {
        filter = '';
    }

    var dataTableWrapper = $('#ArticlesTableWrapper').find('.data-table-block');

    $.ajax({
        url: 'includes/vertraege/ajax/ContractArticlesTableRowsView.php',
        type: 'POST',
        data: {
            customerTenantId: customerTenantId,
            articleTenantId: articleTenantId,
            start_date: interval.start_date,
            start_time: interval.start_time,
            end_date: interval.end_date,
            end_time: interval.end_time,
            filter: filter,
            spec_filter: specFilter,
            location_id: locationId,
            category_id: category
        },
        success: function (data) {

            var dataTablesOptions = setDataTablesOptions(dataTableWrapper);
            $('#contractArticleTable').DataTable().destroy();
            $('#contractArticleTable tbody').html(data);
            $('#contractArticleTable').DataTable(dataTablesOptions);
            $('#contractArticleTable_filter input').val();

            // grays out articles that are already in contract
            grayOutArticlesInContract();

            // filter on enter as well
            $('#contractArticleTable_filter').find('input').on('keypress', function (e) {
                let key = e.which || e.keyCode;
                if (key === 13) {
                    popupArticleTableReload(_customerTenantId, _articleTenantId, interval, this.value, specFilter, locationId, category);

                    $('#ArticlesTableWrapper').addClass('loading');

                }
            });
        }
    });
}

/*
* Gets start - end dates for inputs in Add Article Popup
*/
function getIntervalForAddArticlePopup() {
    let insert_articles_interval = quickverleih.storage.get_inteval_for_insert_article();

    const now = moment();

    let should_set_interval = true;

    // check if interval already set, if not set, they will be updated in the if(should_set_interval) { ... } below
    if(insert_articles_interval.start_date !== null &&
        insert_articles_interval.start_time !== null &&
        insert_articles_interval.end_date !== null &&
        insert_articles_interval.end_time !== null) {

        // check if the already saved interval is in the past, if so, set the intervat again
        let saved_start_date_time = moment(`${insert_articles_interval.start_date} ${insert_articles_interval.start_time}`, "DD.MM.YYYY HH:mm");
        should_set_interval = now.isAfter(saved_start_date_time);
    }
    
    if(should_set_interval) {
        const today = now.format("DD.MM.YYYY");

        let startTime = "00:00";
        let endTime = "00:00";

        if(ENABLE_HOURS) {
            let nextHour = now.add(1, "hour");
            startTime = nextHour.format("HH:00");

            nextHour = nextHour.add(1, "hour");
            endTime = nextHour.format("HH:00");
        }

        quickverleih.storage.set_inteval_for_insert_article(today, startTime, today, endTime);

        insert_articles_interval = {
            start_date:today ,
            start_time: startTime,
            end_date: today,
            end_time: endTime
        }
    }

    return insert_articles_interval;
}

// function triggerBeforeUnload(document) {
//     trigger = document.activeElement;
//     console.log(trigger.id);
//     if (trigger.id != "save_contract") {
//         return "Möchten Sie diese Seite verlassen?";
//     }
// }

// removes subarticles from view
$(function () {

    $(document).on('click', '.delete-subContr-article', function () {

        console.log('in');

        let contractArticleId = $(this).attr('data-article');
        let subContrArticleId = $(this).attr('data-subArtc');
        let subContrArticleNm = $(this).attr('data-subName');
        let subContrPositions = $(this).attr('data-numbers');
        let dataPos = $(this).attr('data-pos');
        let contract_Art_Name = $(this).parent().parent().find('.contractArticleName').html();
        let contract_ShowName = '<p><b>' + contract_Art_Name + '</b><b>' + subContrArticleNm + '</b></p>';

        let data = `<div class="dash-block-header">
                        <div class="message-header-title text-uppercase">${qvapp.i18n.t('remove_article_from_contract_question')}</div>
                    </div>
                    <div class="popup-content">
                        <br>${contract_ShowName}<br>
                        <div class="button delete-button delete-subContr-article-yes" data-article="${contractArticleId}" data-subName="${subContrArticleNm}" data-subArtc="${subContrArticleId}" data-numbers="${subContrPositions}" data-pos="${dataPos}">
                            ${qvapp.i18n.t('remove_article_from_contract_remove')}
                        </div>
                        <a href="#close-modal" rel="modal:close">
                            <div class="button white-button delete-contract-article-no" style="float: right">${qvapp.i18n.t('remove_article_from_contract_cancel')}</div>
                        </a>
                    </div>`;

        openPopup(data, '700px');
    });

    $('.delete-subContr-article-yes').live('click', function () {
        let contractArticleId = $(this).attr('data-article');
        let subContrArticleId = $(this).attr('data-subArtc');
        let subContrArticleNm = $(this).attr('data-subName');
        let subContrPositions = $(this).attr('data-numbers');
        let newlyAddedPos = $(this).attr('data-pos');
        let allSubarticlesIDs = $('#contractArticle_' + contractArticleId).find('.subarticle_ids').val();

        let contract_Art_Name = $('#contractArticle_' + contractArticleId).find('.contractArticleName').html();
        let contract_ShowName = `<p><b>${contract_Art_Name}</b> <b>${subContrArticleNm}</b></p>`;

        let language_modalTitle = qvapp.i18n.t('remove_article_from_contract_confirmation');

        $('#popup .message-header-title').html(language_modalTitle);
        $('#popup .popup-content').html('<br>' + contract_ShowName + '</p><br>');

        // decreases total subarticles counter
        let returnSNumber = $('#contractArticle_' + contractArticleId).find('.artikel_anzahl').val();

        if (!isNaN(returnSNumber) && returnSNumber != '') {
            returnSNumber = parseInt(returnSNumber);
            returnSNumber--;
            if (returnSNumber >= 0) {
                $('#contractArticle_' + contractArticleId).find('.artikel_anzahl').val(returnSNumber);
            }
        }

        // removes selected subarticle from view
        $('#contractArticle_' + contractArticleId).find('[id*="_' + subContrPositions + '"]').parents('.theSub').remove();
        $('#contractArticle_' + contractArticleId).find('[id*="_' + subContrPositions + '"]').remove();
        $('#contractArticle_' + contractArticleId).find('[id*="_' + subContrPositions + '_wrap"]').remove();
        $('#contractArticle_' + contractArticleId).find('[for*="_' + subContrPositions + '"]').remove();
        $('#contractArticle_' + contractArticleId).find('[id*="ret_S_Dates_val_' + subContrPositions + '"]').remove();

        if (newlyAddedPos) {
            $('#contractArticle_' + contractArticleId).find('[id*="' + newlyAddedPos + '"]').parents('.theSub').remove();
            $('#contractArticle_' + contractArticleId).find('[id*="' + newlyAddedPos + '"]').remove();
            $('#contractArticle_' + contractArticleId).find('[id*="' + newlyAddedPos + '_wrap"]').remove();
            $('#contractArticle_' + contractArticleId).find('[for*="' + newlyAddedPos + '"]').remove();
            $('#contractArticle_' + contractArticleId).find('[id*="ret_S_Dates_val_' + newlyAddedPos + '"]').remove();
        }

        allSubarticlesIDs = allSubarticlesIDs.replace(subContrArticleId + ';', '');
        allSubarticlesIDs = allSubarticlesIDs.replace(';' + subContrArticleId, '');
        allSubarticlesIDs = allSubarticlesIDs.replace(subContrArticleId, '');
        $('#contractArticle_' + contractArticleId).find('.subarticle_ids').val(allSubarticlesIDs);

        if ($('.delete-subContr-article[data-article="' + contractArticleId + '"]').length < 1) {
            $('#contractArticle_' + contractArticleId).remove();
        }

        enableSaveButton('#save_contract');
        enableAddSubarticle(contractArticleId);
    });


    quickverleih.contract = (function () {
        /*
        * Add a customer into contract
        * ignoreEdit, can be set to true, to ignore the edit_customer sent by server. as in the case where the 
        */
        var add_customer_to_contract = function (customerId, ignoreEdit = false) {

            $.ajax({
                url: 'includes/vertraege/ajax/ajaxContract.php',
                type: 'POST',
                dataType: 'json',
                data: {
                    customerId: customerId,
                    ignoreEdit: ignoreEdit,
                    method: 'addCustomerInContract'
                },
                success: function (data) {

                    // this is set to true on the server if the customer needs to be edited
                    if (!ignoreEdit && data.edit_customer) {
                        openPopup(data.html, '800px');
                        $('#save_customer').data('edit_from_contract', 1);
                    } else {
                        $('#customerInContractWrapper').html(data.html);
                        closePopup();

                        enableSaveButton('#save_contract');
                    }
                }
            });
        }

        return {
            add_customer_to_contract: add_customer_to_contract
        };
    })();
});

// export section
window.openCustomerTable = openCustomerTable;
window.openArticleTable = openArticleTable;
window.popupArticleTableReload = popupArticleTableReload;