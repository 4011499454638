quickverleih.article = quickverleih.article || {};

quickverleih.article.api = (function () {
    function getKeyForTenantAndLocation(tenant_id, location_id, callback) {
        return quickverleih.api.callServer('api/index.php?tenant_id='+tenant_id+'&location_id='+location_id+'&method=get_return_key', 'GET');
    }
    
    return {
        getKeyForTenantAndLocation   : getKeyForTenantAndLocation
    }

})();