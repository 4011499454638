
quickverleih.settings = quickverleih.settings || {};

quickverleih.settings.api = (function () {

    function addRole(groupName, callback) {

        var data = {
            new_group_name: groupName
        };

        quickverleih.api.callServer('includes/einstellung/api/index.php?method=create_role', 'POST', data, callback);
    }

    function showRolesAndTenants(callback) {

        quickverleih.api.callServer('includes/einstellung/api/index.php?method=show_roles_and_tenants', 'GET', {}, callback);
    }

    function deleteRole(groupId, callback) {

        var data = {
            group_id: groupId
        };

        quickverleih.api.callServer('includes/einstellung/api/index.php?method=delete_role', 'GET', data, callback);
    }

    function createCategory(name, parentId, filterable, callback) {

        var data = {
            name     : name,
            parent_id: parentId,
            filterable: filterable
        };

        quickverleih.api.callServer('includes/einstellung/api/index.php?method=create_category', 'POST', data, callback);
    }
    
    function editCategory(inData, callback) {

        var data = {
            name: inData.categoryName,
            filterable: inData.filterable ? 1 : 0,
            id  : inData.id
        };

        quickverleih.api.callServer('includes/einstellung/api/index.php?method=update_category', 'POST', data, callback);
    }
    
    function updateFilterableForCategories(categories, newFilterableValue, callback) {

        var data = {
            categories: categories,
            filterable: newFilterableValue ? 1 : 0,
        };

        quickverleih.api.callServer('includes/einstellung/api/index.php?method=update_filterable_for_categories', 'POST', data, callback);
    }

    function deleteCategory(id, callback) {

        var data = {
            id: id
        };

        quickverleih.api.callServer('includes/einstellung/api/index.php?method=delete_category', 'GET', data, callback);
    }

    function changeExtendedTimes(extendedTimes, callback) {

        var data = {
            extendedTimes: extendedTimes
        };

        quickverleih.api.callServer('api/index.php?method=change_extended_times', 'GET', data, callback);
    }

    function changeSettings(selectedDatepicker, selectedLoginType, callback) {

        var data = {

            selectedDatepicker: selectedDatepicker,
            selectedLoginType: selectedLoginType

        };

        quickverleih.api.callServer('api/index.php?method=change_shop_settings', 'GET', data, callback);
    }

    function changeWorkingHours(wkHours, callback) {

        var data = {

            wkHours: wkHours

        };

        quickverleih.api.callServer('api/index.php?method=change_working_hours', 'POST', data, callback)

    }

    function changeSemesters(summer_start_date, summer_end_date ,winter_start_date, winter_end_date, 
                            no_classes_summer_start_date, no_classes_summer_end_date ,no_classes_winter_start_date, no_classes_winter_end_date, 
                            callback) {

        var data = {

            summer_start_date : summer_start_date,
            summer_end_date : summer_end_date,
            winter_start_date : winter_start_date,
            winter_end_date : winter_end_date,

            no_classes_summer_start_date : no_classes_summer_start_date,
            no_classes_summer_end_date : no_classes_summer_end_date,
            no_classes_winter_start_date : no_classes_winter_start_date,
            no_classes_winter_end_date : no_classes_winter_end_date
        };

        quickverleih.api.callServer('api/index.php?method=change_semester_date', 'GET', data, callback)
    }

    function changeUserExpDates(sem1, sem2, callback) {

        let data = {
            sem1 :sem1,
            sem2 :sem2
        };

        quickverleih.api.callServer('api/index.php?method=change_user_exp_dates', 'GET', data, callback)
    }

    function addTenant(tenant_data, callback) {
        quickverleih.api.callServer('includes/einstellung/api/index.php?method=add_tenant', 'POST', tenant_data, callback)
    }

    function saveTenant(tenant_data, callback) {
        quickverleih.api.callServer('includes/einstellung/api/index.php?method=save_tenant', 'POST', tenant_data, callback)
    }

    function getTenant(tenant_id, callback) {
        quickverleih.api.callServer('includes/einstellung/api/index.php?method=get_tenant', 'GET', {tenant_id}, callback)
    }

    function deleteTenant(tenant_id, callback) {
        quickverleih.api.callServer('includes/einstellung/api/index.php?method=delete_tenant', 'POST', {tenant_id}, callback)
    }

    function canDeleteTenant(tenant_id, callback) {
        quickverleih.api.callServer('includes/einstellung/api/index.php?method=can_delete_tenant', 'POST', {tenant_id}, callback)
    }

    function addLocation(location_data, callback) {
        quickverleih.api.callServer('includes/einstellung/api/index.php?method=add_location', 'POST', location_data, callback)
    }

    function saveLocation(location_data, callback) {
        quickverleih.api.callServer('includes/einstellung/api/index.php?method=save_location', 'POST', location_data, callback)
    }

    function getLocation(location_id, callback) {
        quickverleih.api.callServer('includes/einstellung/api/index.php?method=get_location', 'GET', {location_id}, callback)
    }

    function deleteLocation(location_id, callback) {
        quickverleih.api.callServer('includes/einstellung/api/index.php?method=delete_location', 'POST', {location_id}, callback)
    }
    
    return {
        addRole   : addRole,
        deleteRole: deleteRole,
        showRolesAndTenants : showRolesAndTenants,

        createCategory: createCategory,
        editCategory  : editCategory,
        updateFilterableForCategories: updateFilterableForCategories,
        deleteCategory: deleteCategory,
        changeSettings: changeSettings,
        changeExtendedTimes: changeExtendedTimes,
        changeWorkingHours: changeWorkingHours,
        changeSemesters: changeSemesters,
        changeUserExpDates: changeUserExpDates,

        addLocation,
        saveLocation,
        getLocation,
        deleteLocation,

        addTenant,
        saveTenant,
        getTenant,
        deleteTenant,
        canDeleteTenant
    }

})();