/* global $, quickverleih, setDataTablesOptions, openPopup, closePopup, openPopupOverPopup */

$(document).ready(function () {

	let requiresConfirmationStateChanged = () => {
		$confFromTeachersContainer = $('.confirmation-from-teachers-select-container');
		// ANDY - 18.03.2022 - ONlY if we have the config param 'hideTeachers = false' we have this section in the ArticleDetailsView
		if($confFromTeachersContainer.length) {
			if($('#articleRequiresConfirmation').is(':checked')) {
				$confFromTeachersContainer.removeClass('hidden');
				$('#confirmationFromTeachers').attr("required", true);
				$('#confirmationFromTeachersRequired').removeClass("hidden");
				// If none of the options are selected - select all
				let newSelections = $('.select2.multiple-checkboxes.confirmationFromTeachers').select2('data');
				if(newSelections.length == 0) {
					$('.select2.multiple-checkboxes.confirmationFromTeachers').select2('destroy').find('option').prop('selected', 'selected').end();
					initMultipleCheckoxesForArticleDetails();
				}
			} else {
				$confFromTeachersContainer.addClass('hidden');
				$('#confirmationFromTeachers').attr("required", false);
				$('#confirmationFromTeachersRequired').addClass("hidden");
			}
		}
	}

	/* Article Locations reload */
	var articleApproversSelectReload = function () {
		let newTenant = $('#articleTenantId').val();
		$.ajax({
			url: 'includes/artikel/ajax/ArticleApproversSelect.php',
			type: 'POST',
			data: {
				"tenant_id": newTenant,
				"selected_teachers": [],
				"select_all": true
			},
			success: function (data) {
				// Reload subarticles location select
				let $approversSelect = $('.main-content.container select.confirmationFromTeachers');
				$approversSelect.empty().append(data);
			}
		});
	}

	var reloadArticleSpecialisations = function () {
		let newTenant = $('#articleTenantId').val();
		$.ajax({
			url: 'includes/artikel/ajax/ArticleSpecialisations.php',
			type: 'POST',
			data: {
				"tenant_id": newTenant,
			},
			success: function (data) {
				// Reload subarticles location select
				let $specialisationsSelect = $('#showForSpecialisations');
				$specialisationsSelect.empty().append(data);
			}
		});
	}

	// Call requires confirmation state changed on start and on chnage
	requiresConfirmationStateChanged();
	$(document).on('click','#articleRequiresConfirmation',function() {
		requiresConfirmationStateChanged();
	});

	var initMultipleCheckoxesForArticleDetails = function() {
		$('.select2.multiple-checkboxes').select2MultiCheckboxes({
			templateSelection: function(selected) {
				if(selected == 0) {
					return "Bitte auswählen"
				}
				return selected.length + " ausgewählt";
			}
		});
	}
	
	var initExpandableTextareas = function() {
		$(".expandable-textarea").each(function () {
			this.setAttribute("style", "width:100%;height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
		}).on("input", function () {
			this.style.height = "auto";
			this.style.height = (this.scrollHeight) + "px";
		});
	}

	var initEditorForArticleDescription = function() {
		quickverleih.editor.init('#articleDescription', $('#save_article'));
	}

	var initFileUpload = function() {
		$('input[type="file"]').change(function () {
			if ($(this).val() != "") {
				var path = $(this).val().split('\\');
				var filename = path[path.length-1];
				$(".upload-file").text(filename);
			} else {
				$(".upload-label").html("Datei auswählen");
				$(".upload-file").text("keine Datei gewählt");
			}
		});
	}

	$(document).on('click', '#articleShowInShop', function () {

		var showInShop = $(this).is(':checked');
		var $showInShopTenants = $('.show-in-shop-tenants-container')

		if(showInShop) {
			$showInShopTenants.show()
		} else {
			$showInShopTenants.hide()
		}
	});

	function init_add_new_warehouse_location_option() {
		// console.log('.main-content.container select.warehouse_location: ' + $('select.warehouse_location').length)

		$(document).on('change', '.main-content.container select.warehouse_location', function() {
			let $this = $(this);

			let selectedWarehouseId = $this.val();

			// this option is added in the function selectWarehouseLocation
			if(selectedWarehouseId == 'add_new_warehouse_location') {
				// reset the selection
				$this.val(0);

				quickverleih.settings.api.showRolesAndTenants(function (response) {
					var tenants = response.tenants;
					quickverleih.settings.system.show_add_new_location_popup(tenants, 
						(server_response_data) => {
							// add the newly added warehouse id to all select elements
							$('.main-content.container select.warehouse_location')
								.find('option:last') // the last option is add_new_warehouse_location
								.before(new Option(server_response_data.name, server_response_data.id));

							// select the newly added warehouse id
							$this.val(server_response_data.id);
						}
					);
				});
			}
		});
	}

	function set_auto_complete(for_new = false){
		var options = {
			adjustWidth: "false",
			url: "./includes/artikel/views/ArticleDetailsView.php?json=true",
			dataType: "json",
			getValue: "name",
			placeholder: "Bitte ein Merkmal eingeben",
			list: {
				match: {
					enabled: true
				}
			},
		};

		// set autocomplete for new inputs
		let selector = "input.article-spezi-attr";
		if(for_new) {
			selector = "input[id*='articleNewSpeziAttr']";
		}

		$(selector).easyAutocomplete(options);
	}

	let returnKeyStateChanged = () => {
		if($('#return_key').is(':checked')) {
			changeShowInShopState(true, "Die Checkbox \"Rückgabeschlüssel\" ist schon aktiviert.", true);
			changeHasSubarticlesState(true, "Die Checkbox \"Rückgabeschlüssel\" ist schon aktiviert.", true);

			// If this is a return key remove the required category attribute
			$('#articleCategory').attr("required", false);
		} else {
			changeShowInShopState(false, "", false);
			changeHasSubarticlesState(false, "", false);

			// If this is NOT a return key add back the required category attribute
			$('#articleCategory').attr("required", true);

			// Check article numbers - from article numbers - needs some more reafactoring against this copy-paste
			// Copy-paste for now
			if($('#articleNumber').val() && $('#articleNumber').val().length > 0){

				$('#articleHasSubarticles').removeAttr('disabled');
				$('.hasSubCheckbox').removeAttr('title');
			}else {
				$('#articleHasSubarticles').attr('disabled', 'disabled');
				$('.hasSubCheckbox').attr('title','Bitte geben Sie zuerst eine Basisartikelnummer ein.');
			}
		}
	}

	let changeHasSubarticlesState = (disabled, popupText, uncheck) => {
		let $hasSubarticles = $('#articleHasSubarticles').prop('disabled', disabled);
		
		if(uncheck) {
			$hasSubarticles.prop('checked', false);
		}

		$hasSubarticles.parent().attr('title', popupText);
	}

	let changeShowInShopState = (disabled, popupText, uncheck) => {
		let $showInShop = $('#articleShowInShop').prop('disabled', disabled);

		if(uncheck) {
			$showInShop.prop('checked', false);
		}
		
		$showInShop.parent().attr('title', popupText);
	}

	let changeReturnKeyState = (disabled, popupText, uncheck) => {
		let $returnKey = $('#return_key').prop('disabled', disabled);

		if(uncheck) {
			$returnKey.prop('checked', false);
		}
		// Not now
		// if(disabled) {
		// 	$("label[for='return_key']").addClass('color-warning');
		// } else {
		// 	$("label[for='return_key']").removeClass('color-warning');
		// }
		$returnKey.parent().attr('title', popupText);
		returnKeyStateChanged();
	}
	
	let checkReturnKeyState = () => {
		let articleHasSubarticles = () => {
			// Key does not exist for tenant and location - check if we have subarticles
			let hasSubarticles = $('#articleHasSubarticles').is(':checked');

			if(hasSubarticles) {
				changeReturnKeyState(true, "Die Checkbox \"Enthält Unterartikel\" ist schon aktiviert.", false);
				return true;
			} else {
				changeReturnKeyState(false, "", false);
				return false;
			}
		};

		let tenant = $('#articleTenantId').val() || 0;
		let location = $('#warehouse_location').val() || 0;
		
		if(tenant != 0 && location != 0) {// Check for subarticles if tenanat and location are set
			if(!articleHasSubarticles()) {
				// Check if key exists for tenant/location only if there are no subarticles
				quickverleih.article.api.getKeyForTenantAndLocation(tenant, location).then( response => {
					if(response && response.success) {
						let artId = $('#articleId').val();
						if(artId != response.id) { // Only if the existing key already defined for this tenant/location is not the one that we are editing
							changeReturnKeyState(true, "Ein anderer Artikel wurde bereits als Rückgabeschlüssel angelegt. Es kann pro Mandant und Lager nur ein Artikel als Typ Rückgabeschlüssel angelegt werden. Bitte passen Sie den Bestand im Eingabefeld Menge an, wenn Sie mehrere Schlüssel für den betreffenden Lagerstandort anlegen möchten.", true);
						}
					}
				});
			}
		} else {
			if(!articleHasSubarticles()) {
				changeReturnKeyState(true, "Bitte wählen Sie zuerst einen Mandanten  und ein Lager aus.", true);
			}
		}
	}

	init_add_new_warehouse_location_option();
	checkReturnKeyState();
	
	function initEditArticle(data) {
		$("#article").remove();
		$("#ArtTable").remove();
		$("#articleFormContainer").remove();

		$(".main-content.container").append(data);

		initMultipleCheckoxesForArticleDetails();
		initExpandableTextareas();
		initEditorForArticleDescription();

		let table = $('#subArticleTable');

		let dataTablesOptions = setDataTablesOptions($(data));
		table.DataTable(dataTablesOptions);

		$('#subArticleTable_wrapper').addClass('one-full');

		// creates new and export buttons for subarticles
		const buttonMarkup = `<div id="subArticlesButtons" class="popup-div-content" >
										<div class="button submit-button-inactive" id="add_subarticle_number">NEUE UNTERARTIKELNUMMER</div>
										<div class="button submit-button-inactive button-right" id="export_ean_codes">ALLE EAN CODES EXPORTIEREN</div>
								</div>`;

		$('.data-table-header').after(buttonMarkup);

		set_auto_complete();

		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 0)
	}

	/*
	*	New Article popup
	*/
	$(document).on('click', '#new_article', function () {
		
		$.ajax({
			url: 'includes/artikel/ajax/ajaxArticle.php',
			type: 'POST',
			data: {
				method: 'renderNewArticle'
			},
			success: function (data) {
				initEditArticle(data);
				$('#deleteArticle').addClass('hideButton');
			}
		});

		return false;

	});

	$(document).on('click', '#save_article', function () {
		if ($(this).hasClass('submit-button-disabled')) {
			return;
		}


		let borderKO = '#ff0000';

		let errors = false;
		$('#basisDaten').find('[required]').each(function (index, elem) {
			let tempVal = $(elem).val();
			// console.log($(elem).attr('id') + ' -> ' + tempVal)

			if((!tempVal /* for select2 elements */) || typeof tempVal === 'undefined' || tempVal == '' || tempVal == 0 /* for select elements */) {
				errors = true;
				$(elem).css({ 'border': '1px solid ' + borderKO });
				if($(elem).attr('id') === 'confirmationFromTeachers') {
					$(elem).data('select2').$container.addClass("input-error");
				}
			}
		});

		let artId = $('#articleId').val();
		let tenantId = $('#articleTenantId').val();

		let subarticleNumbersOk = checkSubarticleNumbers();

		if (subarticleNumbersOk === false) {
			var message = 'Bitte geben Sie Werte für alle Subartikelnummern ein.';

			var html = '<div class="dash-block-header error-popup"> <div class="message-header-title text-uppercase">';
			html += 'Fehler';
			html += '</div></div>';
			html += '<div class="popup-content">';
			html += '<br><p><b>' + message + '</b></p><br>';
			openPopupOverPopup(html, '500px');

			errors = true;
		}

		if (errors === true) {
			return false;

		} else {
			// checks if the save button is disabled
			if ($('#save_article').css('submit-button-disabled')) {
				errors = true;
			}

			// checks if item has stock
			if ($('#articleQty').val() < 1) {
				$('#articleQty').css('border-color', '#eb547c');
				errors = true;
			}

			if (!errors) {
				let tempSubIds = $('#new_subarticle_ids').val();
				let newSubArray = tempSubIds.split(',');
				let newSubarticlesData = [];

				if (newSubArray.length > 0) {

					newSubArray.forEach(function (tempId) {
						if (tempId !== '') {
							let subarticle_prefix = $('#subarticle_prefix_' + tempId).val();
							let subarticle_number = $('#subarticle_number_' + tempId).val();
							let warehouse_location = $('#warehouse_location_' + tempId).val();
							let storage_place = $('#storage_place_' + tempId).val();
							let storage_compartment = $('#storage_compartment_' + tempId).val();

							newSubarticlesData.push({
								tempId,
								subarticle_prefix,
								subarticle_number,
								warehouse_location,
								storage_place,
								storage_compartment
							});
						}
					});
				}

				var options = {
					success: function (data) {
						doEditArticle(data.articleId);
					},

					url: 'includes/artikel/ajax/ajaxArticle.php',
					data: { 
						method: 'saveArticle',
						// send the new subarticles as json to the server
						new_subarticles_data: JSON.stringify(newSubarticlesData)
					},
					dataType: 'json'
				};

				$('#articleForm').ajaxSubmit(options);
			}
		}
	});

	function doEditArticle(articleId)
	{
		$.ajax({
			url: 'includes/artikel/ajax/ajaxArticle.php',
			type: 'POST',
			data: {
				articleId: articleId,
				method: 'renderEditArticle'
			},

			success: function (data) {
				initEditArticle(data);
				$('#cancelArticle').addClass('hideButton');
			}
		});
	}

	$(document).on('click', '.edit-article', function () {

		var articleId = $(this).attr('id-article');
		doEditArticle(articleId)
	});

	$(document).on('click', '.search-art', function () {
		let filter = $(this).attr('gnr-article');
		window.location.href = '/index.php?page=4-2_Artikeluebersicht&article_filter=' + filter;
	});


	/*
	* Add New Spezi
	*/
	$(document).on('click', '#new_spezi', async function () {
		let articleId = $('#articleId').val();
		let new_specs_rows_count = $('#article_spezi_table .newspezi').length;

		let onScreenSubarticles = {};
		$('.subarticle-number-contaier').find('.subarticle-prefix').map((i, elem)=> {
			// onScreenSubarticles.push($(elem).val());
			$number = $(elem).siblings('.subarticle-number');
			onScreenSubarticles[$(elem).val().toUpperCase()+"-"+$number.val()] = {
				'prefix': $(elem).val().toUpperCase(),
				'number': $number.val()
			}
		});

		let spezi_html = await quickverleih.api.render_php('./includes/artikel/ajax/ajaxArticle.php', {
			method : "new_spec_row_html",
            new_specs_rows_count,
			articleId,
			subarticle_numbers_on_screen: onScreenSubarticles
        });

		$('#article_spezi_table tbody').append(spezi_html);
		initMultipleCheckoxesForArticleDetails();
		initExpandableTextareas();

		set_auto_complete(true);
	});



	$(document).on('click', '.delete-article', function () {

		if ($(this).hasClass('delete-button')) {

			var articleId = $(this).attr('id-article');
			var articleName = $('#articleName').val();

			let data = `<div class="dash-block-header">
					<div class="message-header-title text-uppercase">Bestätigung Artikel löschen</div>
				</div>
				<div class="popup-content">
					<br>
					<p><b>Möchten Sie diesen Artikel löschen? :</b></p>
    				<p><b>${articleName}</b></p>
    				<br>
    				<div class="button delete-button delete-article-yes" data-id=${articleId}>ARTIKEL LÖSCHEN?</div>
    				<a href="#close-modal" rel="modal:close">
        				<div class="button white-button delete-article-no" style="float: right">ABBRECHEN</div>
    				</a>
    			</div>`;

			openPopupOverPopup(data, '500px');
		}

	});

	$(document).on('click', '.delete-article-yes', function () {

		var articleId = $(this).attr('data-id');

		$.ajax({
			url: 'includes/artikel/ajax/ajaxArticle.php',
			type: 'POST',
			dataType: 'html',
			data: {
				articleId: articleId,
				method: 'deleteArticle'
			},
			success: function (data) {
				let message = 'Der Artikel wurde gelöscht.';

				let html = `<div class="dash-block-header">
    						<div class="message-header-title text-uppercase">Bestätigung Artikel löschen</div>
						</div>
						<div class="popup-content">
    						<br><p><b>${message}</b></p><br>
						</div>`;

				openPopup(html, '500px');

				setTimeout(function () {
					window.location.href = '/index.php?page=4-2_Artikeluebersicht';
					closePopup();
				}, 2000);
			}
		});

	});


	/**
	 *  Sub-article Delete Modal
	 */


	$(document).on('click', '.delete-subarticle-number', function () {

		$('#subArticleTable').find('.delSelect').removeClass('delSelect');

		let subArticleId = $(this).attr('id-subarticle');

		let data = `<div class="dash-block-header">
    					<div class="message-header-title text-uppercase">Bestätigung Unterartikel löschen</div>
					</div>
					<div class="popup-content">
					<br>
					<p><b>Möchten Sie den Unterartikel wirklich löschen?</b></p>
					<br>
   						<div class="button delete-button delete-subarticle-yes" data-id="${subArticleId}">UNTERARTIKEL LÖSCHEN</div>
    					<a href="#close-modal" rel="modal:close">
        					<div class="button white-button delete-subarticle-no" style="float: right">ABBRECHEN</div>
    					</a>
					</div>`;

		openPopupOverPopup(data, '500px');

		$(this).closest('tr').addClass('delSelect');

	});

	/**
	 *  Sub-article Delete
	 */


	$(document).on('click', '.delete-subarticle-yes', function () {

		// Lowers Lagerbestand by 1
		$('#articleQty').val(function (i, oldval) {
			return --oldval;
		});

		let subArticleId = $(this).attr('data-id');
		let articleId = $('#articleId').val();
		let subArtQty = $('#articleQty').val();

		if (subArtQty < 0) {
			$('#articleQty').val(0);
		}

		if ($("input[id^='subarticle_number_new_subId_']").closest('tr').hasClass('delSelect')) {

			let saveArray = $('#new_subarticle_ids').val().split(',');

			saveArray.forEach(function (tempId) {
				if (subArticleId === tempId) {
					let index = saveArray.indexOf(subArticleId);
					saveArray.splice(index, 1);
				}
				$('#new_subarticle_ids').val(saveArray);
			});

			let message = 'Der Unterartikel wurde gelöscht.';
			let html = `<div class="dash-block-header">
										<div class="message-header-title text-uppercase">Bestätigung Unterartikel löschen</div>
									</div>
									<div class="popup-content">
									<br><p><b>${message}</b></p><br>
									</div>`;

			openPopup(html, '500px');
			setTimeout(function () {
				$('#subArticleTable').DataTable().row('.delSelect').remove().draw();
				closePopup();
			}, 2000);

			enableSaveButton('#save_article');

		} else {

			$.ajax({
				url: 'includes/artikel/ajax/ajaxArticle.php',
				type: 'POST',
				dataType: 'html',
				data: {
					articleId: articleId,
					subArticleId: subArticleId,
					method: 'deleteSubArticle'
				},
				success: function (data) {

					let message;

					if (data == true) {
						message = 'Der Unterartikel wurde gelöscht.';

						let html = `<div class="dash-block-header">
										<div class="message-header-title text-uppercase">Bestätigung Unterartikel löschen</div>
									</div>
									<div class="popup-content">
									<br><p><b>${message}</b></p><br>
									</div>`;

						openPopup(html, '500px');
						setTimeout(function () {
							$('#subArticleTable').DataTable().row('.delSelect').remove().draw();
							closePopup();
						}, 2000);

						// enableSaveButton('#save_article');

					} else {
						message = 'Ein Problem ist aufgetreten. Bitte prüfen Sie Ihre Datenverbindung';

						let html = `<div class="popup-content">
									<br><p><b>${message}</b></p><br>
									</div>`;

						openPopup(html, '500px');

					}
				}
			});
		}

		// removed the last one
		if ($('#subArticleTable').find('.delete-subarticle-number').length === 1) {
			$('#articleQty').attr("readonly", false);
			$('#articleHasSubarticles').prop('checked', false);
			$('.subArticlesContainer').attr('hidden', true);
		}

	});
	
	function add_attachment() {

		const post_parameters = {}
		post_parameters.idmaterial = idmaterial;

		$.post("includes/artikel/ajax/add_attachment.php",
			post_parameters,
			function(data) {
				openPopupOverPopup(data, '500px');
				initFileUpload();
			}
		);
	}

	$(document).on('click', '.attachment_upload-trigger', function () {
		add_attachment();

		return false;
	});

	function add_pic() {

		let post_parameters = {}
		post_parameters.idmaterial = idmaterial;

		$.post("includes/artikel/ajax/add_pic.php",
			post_parameters,
			function(data) {
				openPopupOverPopup(data, '500px');
				initFileUpload();
			}
		);
	}

	$(document).on('click', '.fileupload-trigger', function () {
		add_pic();

		return false;
	});

	$(document).on('click', '.delete-article-spezi', function () {

		var articleSpeziId = $(this).attr('id-spezi');
		var articleSpeziAttr = $(this).parents('tr').find('.article-spezi-attr').val();

		var data = '<div class="dash-block-header"> <div class="message-header-title text-uppercase">';
		data += 'Möchten Sie die Spezifikation zu diesem Artikel löschen?';
		data += '</div></div>';
		data += '<div class="popup-content">';
		data += '<br><p><b>' + articleSpeziAttr + '</b></p><br>';
		data += '<div class="button delete-button delete-article-spezi-yes" data-id="' + articleSpeziId + '">Löschen' /*+ qvapp.i18n.t('remove_article_from_contract_remove') */ + '</div>';
		data += '<a href="#close-modal" rel="modal:close"><div class="button white-button delete-article-spezi-no" style="float: right">Abbrechen' /*+ qvapp.i18n.t('remove_article_from_contract_cancel') */ + '</div></a>';

		openPopupOverPopup(data, '500px');

	});


	$(document).on('click', '.delete-article-spezi-yes', function () {

		var articleSpeziId = $(this).attr('data-id');
		var articleId = $('#articleId').val();

		if (articleId > 0) {	// delete from DB
			$.ajax({
				url: 'includes/artikel/ajax/ajaxArticle.php',
				type: 'POST',
				async: false,
				data: {
					articleSpeziId: articleSpeziId,
					articleId: articleId,
					method: 'deleteArticleSpezi'
				},
				success: function (data) {
					// confirmation
					var title = 'Bestätigung Artikelspezifikation löschen';
					var content = '<br><p><b>' + data + '</b></p><br>';
					$('#popup1 .message-header-title').html(title);
					$('#popup1 .popup-content').html(content);
				}
			});

			// remove spezi from DOM
			$('#articleSpeziId_' + articleSpeziId).parent().parent().remove();

		} else {
			//remove spezi from DOM
			$(this).parent().parent().remove();
		}

	});

	/* delete not saved spezi */
	$(document).on('click', '.delete-article-spezi-NEW', function () {
		$(this).parents('tr').remove();
	});


	/* Open New Article popup if we have NEU param in URL */
	if ($('#new_article_param').val() == 1) {
		$('#new_article').click();
	}


	$(document).on('click', '.delete-article-pic', function () {

		var articlePic = $(this).attr('pic');

		var data = '<div class="dash-block-header"> <div class="message-header-title text-uppercase">';
		data += 'Möchten Sie das Artikelbild löschen?';
		data += '</div></div>';
		data += '<div class="popup-content"><div style="text-align: center; margin-bottom: 1rem">';
		data += '<br><img src="../storage/products/' + articlePic + '" style="max-width: 14rem; max-height: 14rem" alt="Artikelbild"><br>';
		data += '</div><div class="button delete-button delete-article-pic-yes" data-id="' + articlePic + '">ARTIKELBILD LÖSCHEN' /*+ qvapp.i18n.t('remove_article_from_contract_remove') */ + '</div>';
		data += '<a href="#close-modal" rel="modal:close"><div class="button white-button delete-article-pic-no" style="float: right">ABBRECHEN' /*+ qvapp.i18n.t('remove_article_from_contract_cancel') */ + '</div></a>';

		openPopupOverPopup(data, '500px');

	});

	$(document).on('click', '.delete-article-pic-yes', function () {

		var articlePic = $(this).attr('data-id');

		del_pic(articlePic);

		// confirmation
		var title = 'Bestätigung zur Löschung des Artikelbildes';
		var content = '<br><p><b>Das Artikelbild wurde gelöscht</b></p><br>';
		$('#popup1 .message-header-title').html(title);
		$('#popup1 .popup-content').html(content);
	});

	$(document).on('keyup', '#articleNumber', function () {
		var acceptable = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '-', '.', ' ', '$', '/', '+', '%'];
		var allowed = charsInArray($(this).val(), acceptable);

		if (allowed) {
			$(this).removeClass('red');
			$('#articleNumberError').addClass('hidden');
		} else {
			$(this).addClass('red');
			$('#articleNumberError').removeClass('hidden');
		}

	});


	// - Today's date dd.mm.yy

	function theDate() {

		let today = new Date();
		let dd = today.getDate();
		let mm = today.getMonth() + 1;
		let yy = today.getFullYear().toString().substr(-2);
		if (dd < 10) {
			dd = '0' + dd
		}
		if (mm < 10) {
			mm = '0' + mm
		}
		today = dd + '.' + mm + '.' + yy;

		return today;

	}



	// - New Comment
	$(document).on('click', '.new-comment', function () {

		$(this).closest('.subArtButton').addClass('hidden');
		$(this).closest('.subArtButton').siblings('.new-comment').removeClass('hidden');
		$(this).closest('.subArtButton').siblings('.new-comment').find('.subArtComment').removeClass('comment-warning').addClass('comment-color');

		$(this).closest('tr').find('.delete-subarticle-number').addClass('hidden');


	});

	// -  Cancel Comment

	$(document).on('click', '.cancel-button', function () {

		$(this).closest('tr').find('.delete-subarticle-number').removeClass('hidden');
		$(this).closest('.subArtInputs').find('.subArtComment').val('');

		$(this).closest('.new-comment').addClass('hidden');
		$(this).closest('.new-comment').siblings('.subArtButton').removeClass('hidden');

	});


	$(document).on('click', '.cancel-button-edit', function () {

		let oldText = $(this).closest('.comment-container').find('.comment-text').text();
		let userAndDate = $(this).closest('.comment-container').find('.comment-date').text();

		$(this).closest('tr').find('.delete-subarticle-number').removeClass('hidden');
		$(this).closest('.subArtInputs').addClass('hidden');
		$(this).closest('.comment-container').find('.comment-box').removeClass('hidden');
		$(this).closest('.comment-container').find('.comment-text').text(oldText);
		$(this).closest('.comment-container').find('.comment-date').text(userAndDate);

	});

	// - Edit Comment


	$(document).on('click', '.edit-comment', function () {

		let comment = $(this).siblings('.comment-text')[0].innerText;

		$(this).closest('.comment-box').addClass('hidden');
		$(this).closest('.comment-box').siblings('.subArtInputs').removeClass('hidden');
		$(this).closest('tr').find('.delete-subarticle-number').addClass('hidden');
		$(this).closest('.comment-box').siblings('.subArtInputs').find('.subArtComment').val(comment);

	});


	/**
	 *	Delete comment modal
	 */
	$(document).on('click', '.delete-comment', function () {

		let commentId = this.getAttribute('id-comment');
		let subId = $(this).attr('id-subarticle');

		let data = `<div class="dash-block-header"> 
    					<div class="message-header-title text-uppercase">Bestätigung Kommentar löschen</div>
					</div>
					<div class="popup-content">
					<br>
					<p><b>Möchten Sie den Kommentar wirklich löschen?</b></p>
					<br>
   						<div class="button delete-button delete-comment-yes" comment-id ="${commentId}"  sub-id="${subId}">KOMMENTAR LÖSCHEN</div>
   						<a href="#close-modal" rel="modal:close">
       						<div class="button white-button delete-subarticle-no" style="float: right">ABBRECHEN</div>
   						</a>
					</div>`;

		openPopupOverPopup(data, '500px');

	});

	/**
	 * 	Delete comment -confirmation
	 */

	$(document).on('click', '.delete-comment-yes', function () {

		let subId = $(this).attr('sub-id');
		let commentId = $(this).attr('comment-id');

		if ($("div[id^='comment_number_new_comId_']").length) {

			let saveArray = $('#new_comment_ids').val().split(',');

			saveArray.forEach(function (tempId) {
				if (commentId === tempId) {
					let index = saveArray.indexOf(commentId);
					saveArray.splice(index, 1);
				}
				$('#new_comment_ids').val(saveArray);

				let message = "Der Kommentar wurde gelöscht.";
				yesCommentDelete(commentId, message)

			})

		} else {

			$.ajax({
				url: 'components/SubArticleComments/ajax/ajax.php',
				type: 'POST',
				data: {
					subId: subId,
					commentId: commentId,
					method: 'deleteComment'
				},
				success: function (data) {
					yesCommentDelete(commentId, data);
				}
			});
		}
	});
	
	/**
	*	Delete attachment modal
	*/
	$(document).on('click', '.delete-attachment', function () {

		let data = `<div class="dash-block-header"> 
					   <div class="message-header-title text-uppercase">ANLEITUNG LÖSCHEN?</div>
				   </div>
				   <div class="popup-content">
				   <br>
				   <p><b>Möchten Sie die Anleitung wirklich löschen?</b></p>
				   <br>
						  <div class="button delete-button delete-attachment-yes">ANLEITUNG LÖSCHEN</div>
						  <a href="#close-modal" rel="modal:close">
							  <div class="button white-button delete-subarticle-no" style="float: right">ABBRECHEN</div>
						  </a>
				   </div>`;

		openPopupOverPopup(data, '500px');
	});

   /**
	* 	Delete attachment -confirmation
	*/

   	$(document).on('click', '.delete-attachment-yes', function () {

		var articleId = $('#articleId').val();
	   	$.ajax({
			url: 'includes/artikel/ajax/delete_attachment.php',
			type: 'POST',
			data: {
				idmaterial: articleId
			},
			success: function (data) {
				$('#show-attachment').addClass('hidden');
				$('#show-attachment').hide();
				$('#show-upload-attachment-button').removeClass('hidden');
				$('#show-upload-attachment-button').show();
				closePopup();

				$("attachement_uid").val("");
				$("attachement_orig_name").val("");
			}
		});
   	});

	$(document).on('keyup change', '.subarticle-number', function () {

		let $this = $(this);
		let currentVal = $(this).val();
		
		let is_duplicate = false;

		if(currentVal !== "")
		{
			$('.subarticle-number').not($this).each(function () {
				if ($(this).val() == currentVal) {
					is_duplicate = true;
					return false;
				}

			});
		}

		if (is_duplicate) {

			$this.css('border-color', '#eb547c');
			$this.addClass('warning-error');

			disableSaveButton('#save_article');
		} else {

			$this.css('border-color', '#eaeaea');
			$this.removeClass('warning-error');

			enableSaveButton('#save_article');
		}
	});

	$(document).on('click','#return_key',function() {
		returnKeyStateChanged();
	});

	$(document).on('input','#articleNumber',function () {
	
		if($('#articleNumber').val().length > 0){
	
			$('#articleHasSubarticles').removeAttr('disabled');
			$('.hasSubCheckbox').removeAttr('title');

			checkReturnKeyState();
		}else {
			$('#articleHasSubarticles').attr('disabled', 'disabled');
			$('.hasSubCheckbox').attr('title','Bitte geben Sie zuerst eine Basisartikelnummer ein.');

			checkReturnKeyState();
		}
	});

	$(document).on('click','#articleHasSubarticles',function() {
		checkReturnKeyState();
	});

	$(document).on('change','#warehouse_location',function() {
		checkReturnKeyState();
	});

	$(document).on('change','#articleTenantId',function() {
		checkReturnKeyState();

		articleApproversSelectReload();
		reloadArticleSpecialisations();
	});
});

function yesCommentDelete(commentId, data) {

	let html = `<div class="dash-block-header">
    							<div class="message-header-title text-uppercase">Bestätigung Kommentar löschen</div>
						   </div>
						   <div class="popup-content">
    							<br><p><b>${data}</b></p><br>
						   </div>`;
	openPopup(html, '500px');

	setTimeout(function () {
		closePopup();
		$("#subArticleTable").find("[id-comment='" + commentId + "']").first().closest('.comment-container').remove();
	}, 2000);
}
/*
* Article Functions
*/

/*
* Check Subarticle Numbers For Empty Fields
*/
function checkSubarticleNumbers() {
	// rc: allow for now empty and chars in suffix
	return true;

	var numbersOk = true;

	$('.subarticle-number').each(function (i, el) {
		if (el.value == '' || (el.value !== '' + parseInt(el.value, 10))) {
			numbersOk = false;
			$('#' + el.id).addClass('input-error');
		} else {
			$('#' + el.id).removeClass('input-error');
		}
	});

	return numbersOk;
}

// ANDY - 14 Febr 2022 - Articles list header based on article_filter(used to be just filter)
$(document).on('ready', function () {

	let params = getParameter('article_filter');

	if (!params) {
		params = '';
	} else {

		// ANDY - 14 Febr 2022 - No longer search for the article based on cut subarticle prefix - the prefix can be changed now
		// if (params.includes('-')) {
		// 	params = params.substr(0, params.indexOf('-'));
		// }

		// ANDY - 14 Febr 2022 - SHow the search string inside the table title
		$('.data-table-name').text(' ARTIKEL ' /*+ params*/);
		// ANDY - 14 Febr 2022 - Do not show the reset button anymore just remove the text from the search field and that's it
		// COULD BE UNCOMMENTED IN THE FUTURE AS A HELPER TO REMOVE THE FILTER TEXT - Just comment for now
		// let resetButton = `<div class="button submit-button resetFilter" style="display: flex; align-items: center; justify-content: center; float: right; margin-left: 30px">Zeige mehr Artikel</div>`;
		// $('.data-table-name').parent().append(resetButton);

		// ANDY - 14 Febr 2022 - SHow the search string inside the search field
		$('#articleTable_filter').find('input').val(params);

	}

	// ArticleTableLoad(params);

	$('#articleTable_filter').find('input').on('keypress', function (e) {

		let key = e.which || e.keyCode;
		if (key === 13) {
			ArticleTableLoad(this.value);
		}
	});

	$('.resetFilter').on('click', function () {
		ArticleTableLoad('');
		$('.data-table-name').text(' ARTIKEL');

		let uri = window.location.toString();
		if (uri.indexOf("?") > 0) {
			let clean_uri = uri.substring(0, uri.indexOf("?"));
			window.history.replaceState({}, document.title, clean_uri);
		}

	});
});


function ArticleTableLoad(filter) {

	let critStock = false;

	if ($('#articleTable_filter').closest('#crit_wrap_id').length) {
		critStock = true;
		$('#ArticlesTableWrapper').addClass('loading');
	}

	$('#ArtTable').addClass('loading');

	$.ajax({
		type: "GET",
		url: "includes/artikel/ajax/ArticlesTableRowsView.php?" + "&article_filter=" + filter + "&crit_stock=" + critStock,
		success: function (data) {

			let table = $('#articleTable').DataTable();

			table.clear();

			$(data).each(function () {

				if ($(this).is('tr')) {

					table.row.add($(this));
				}
			});

			table.draw();

			setTimeout(function () {

				$('#ArtTable').removeClass('loading');
				$('#ArticlesTableWrapper').removeClass('loading');
			}, 200);
		}
	});

}

function getParameter(theParameter) {
	var params = window.location.search.substr(1).split('&');

	for (var i = 0; i < params.length; i++) {
		var p = params[i].split('=');
		if (p[0] == theParameter) {
			return decodeURIComponent(p[1]);
		}
	}
	return false;
}

function mark_as_valid($input) {
	enableSaveButton('#save_article');
	$input.removeClass('red');
}

function mark_as_not_valid($input) {
	disableSaveButton('#save_article');
	$input.addClass('red');
}

$(document).on('input', '#articleQty', function (e) {
	e.preventDefault();

	if (!$('#articleHasSubarticles').is(':checked')) {

		var $countInpput = $(this);

		let qty = parseInt($countInpput.val());
		let articleId = $('#articleId').val();

		if (isNaN(qty) || qty == 0) {
			mark_as_not_valid($countInpput);
			return;
		}

		// console.log('articleId',articleId);

		$.ajax({
			url: 'includes/artikel/ajax/ajaxArticle.php',
			type: 'POST',
			data: {
				articleId: articleId,
				method: 'getArticlesInUse'
			},
			success: function (data) {
				// console.log('data',parseInt(data));

				if (parseInt(data) >= qty) {

					console.log('cannot save');

					mark_as_not_valid($countInpput);

				} else {
					console.log('can save');

					mark_as_valid($countInpput);
				}



			}
		});

	}
});