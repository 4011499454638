
quickverleih.settings = quickverleih.settings || {};
quickverleih.settings.organisation = (function () {

    var selector = 'form.organisation-form';
    var content  = false;

    function init() {
        content = $(selector);

        _handleLogoUpload();

        _addNewRole();
        _deleteRole();

        quickverleih.settings.api.showRolesAndTenants(function (response) {

            var roles = response.roles
            var tenants = response.tenants
            _addNewEmployee(roles, tenants);
            _editEmployee(roles, tenants);
        });

        _deleteEmployee();
    }

    function _handleLogoUpload() {

        var uploadBtn   = content.find('.for-logo');
        var uploadInput = content.find('#basics_logo');

        var initialTxt = uploadBtn.text();

        var companyLogo = content.find('.company-logo');

        function _readURL(input, wrapper) {

            if (input.files && input.files[0]) {
                var reader = new FileReader();

                reader.onload = function (e) {

                    wrapper.css('background-image', 'url(' + e.target.result + ')');
                };

                reader.readAsDataURL(input.files[0]);
            }
        }

        var _handleBtnTxt = function (e) {
            var elem     = $(this);
            var filename = '';

            var lastIndex = (elem.val() || '').lastIndexOf('\\');

            if (elem.val() && lastIndex >= 0) {
                filename = elem.val().substring(lastIndex + 1);

                // uploadBtn.text(filename).attr('title', filename);
                _readURL(elem.get(0), companyLogo);
            } else {

                // uploadBtn.text(initialTxt).attr('title', filename);
            }
        };

        uploadInput.off('change').on('change', _handleBtnTxt);
    }

    function _addNewRole() {

        var addRoleBtn = content.find('.add-new-role');
        console.log(`add role: ${addRoleBtn.length}`)

        var _addRoleFn = function (e) {

            var elem = $(this);

            var popupContent = quickverleih.template.render('./includes/einstellung/templates/new_role_popup._', {
                roleId  : elem.attr('data-role-id'),
                roleName: elem.attr('data-role-name')
            });

            var popup = openPopup(popupContent, '500px');

            var groupName = popup.find('input[name="new_group_name"]');
            var addBtn    = popup.find('.add-role-yes');
            var cancelBtn = popup.find('.add-role-no');

            addBtn.off('click').on('click', function (e) {
                e.preventDefault();

                quickverleih.settings.api.addRole(groupName.val(), function (result) {

                    console.log('ADD', result);
                    cancelBtn.click();

                    setTimeout(function () {

                        location.reload(true);
                    }, 200);
                });
            });
        };

        addRoleBtn.off('click').on('click', _addRoleFn);
    }

    function _deleteRole() {

        var deleteRoleBtn = content.find('.delete-role');

        var _deleteRoleFn = function (e) {
            var elem = $(this);

            var popupContent = quickverleih.template.render('./includes/einstellung/templates/delete_role_popup._', {
                roleId  : elem.attr('data-role-id'),
                roleName: elem.attr('data-role-name')
            });

            var popup = openPopup(popupContent, '500px');

            var deleteBtn = popup.find('.delete-role-yes');
            var cancelBtn = popup.find('.delete-role-no');

            deleteBtn.off('click').on('click', function () {

                quickverleih.settings.api.deleteRole(deleteBtn.attr('data-group-id'), function (result) {

                    console.log('DELETE', result);
                    cancelBtn.click();

                    setTimeout(function () {

                        location.reload(true);
                    }, 200);
                });
            });
        };

        deleteRoleBtn.off('click').on('click', _deleteRoleFn);
    }

    function _addNewEmployee(roles, tenants) {

        var addNewEmpBtn = content.find('.add-new-employee');
        var mode         = 'new';

        var employeeTable = content.find('#employee_table');

        var _addNewEmpFn = function (e) {
            var elem = $(this);

            console.log('add new emp clicked');

            var popupContent = quickverleih.template.render('./includes/einstellung/templates/employee_popup._', {
                mode    : mode,
                employee: {},
                roles   : roles || {},
                tenants : tenants || {}
            });

            var popup = openPopup(popupContent, '800px');

            var addBtn    = popup.find('.add-employee-yes');
            var cancelBtn = popup.find('.add-employee-no');

            _imageUpload(popup);

            addBtn.off('click').on('click', function () {

                var form = popup.find('form');

                if (_validateInputs(popup) === false) {

                    return;
                }
                //console.log('add button clicked');

                var options = {
                    url     : 'includes/employees/api/index.php?method=create_employee',
                    type    : 'POST',
                    datatype: 'application/json',
                    success : function (data) {
                        var editButton = '', deleteButton = '';

                        var dataTable = employeeTable.DataTable();

                        //console.log(data);

                        // .............................................
                        // checks for existing username
                        // .............................................
                        if(isNaN(data) && typeof data === 'string' && data.indexOf('existing username') >= 0)
                        {
                			var message = 'Dieser Benutzername ist schon vergeben.';
                			showAlert(message);
                            return false;
                        }
                        // .............................................

                        if (data && data.a_id) {

                            editButton   = '<button type="button" data-employee-id="' + data.a_id +'" class="reset-button data-table-content-action-button edit-employee">' +
                                '<svg class="icon" style="width: 18px; height: 18px;">' +
                                '<use xlink:href="#icon-pen"></use>' +
                                '</svg>' +
                                '</button>';
                            deleteButton = '<button type="button" data-employee-name="' + data.name + '" data-employee-id="' + data.a_id + '" class="reset-button x-button delete-employee">' +
                                '<svg class="icon" style="width: 13px; height: 13px;">' +
                                '<use xlink:href="#icon-x"></use>' +
                                '</svg>' +
                                '</button>';

                            dataTable.row.add([
                                data.name,
                                data.kurzname,
                                data.role,
                                data.email,
                                editButton,
                                deleteButton,
                            ]).draw(false);

                            // reload these 2 functions
                            _editEmployee(roles, tenants);
                            _deleteEmployee();
                        }

                        cancelBtn.click();
                    },
                    error   : function (data) {

                        console.log(data);
                    }
                };

                form.ajaxSubmit(options);
            });
        };

        addNewEmpBtn.off('click').on('click', _addNewEmpFn);
    }

    function _editEmployee(roles, tenants) {

        var mode       = 'edit';

        var employeeTable = content.find('#employee_table');

        var _editEmpFn = function (e) {
            var elem       = $(this);
            var employeeId = elem.attr('data-employee-id');

            console.log('edit', employeeId);
            if (parseInt(employeeId) > 0) {

                quickverleih.employees.api.showEmployee(employeeId, function (employee) {

                    var popupContent = quickverleih.template.render('./includes/einstellung/templates/employee_popup._', {
                        mode    : mode,
                        employee: employee,
                        roles   : roles || {},
                        tenants : tenants || {}
                    });

                    var popup = openPopup(popupContent, '800px');

                    var editBtn   = popup.find('.add-employee-yes');
                    var cancelBtn = popup.find('.add-employee-no');

                    _imageUpload(popup, employee);

                    editBtn.off('click').on('click', function () {

                        var form = popup.find('form');

                        if (_validateInputs(popup) === false) {

                            return;
                        }
                        //console.log('edit button clicked');

                        var options = {
                            url     : 'includes/employees/api/index.php?method=edit_employee',
                            type    : 'POST',
                            datatype: 'application/json',
                            success : function (data) {

                                //console.log(data);

                                // .............................................
                                // checks for existing username
                                // .............................................
                                if(isNaN(data) && typeof data === 'string' && data.indexOf('existing username') >= 0)
                                {
                        			var message = 'Dieser Benutzername ist schon vergeben.';
                        			showAlert(message);
                                    return false;
                                }
                                // .............................................

                                //console.log('updated');

                                var rowParent = elem.parents('tr');
                                var dataTable = employeeTable.DataTable();

                                if (rowParent && rowParent[0] && (data && data.a_id)) {

                                    var rowData = dataTable.row(rowParent[0]).data();

                                    rowData[0] = data.name;
                                    rowData[1] = data.kurzname;
                                    rowData[2] = data.role;
                                    rowData[3] = data.email;

                                    dataTable.row(rowParent[0]).data(rowData).draw(false);

                                    // reload these 2 functions
                                    _editEmployee(roles, tenants);
                                    _deleteEmployee();
                                }

                                cancelBtn.click();
                            },
                            error   : function (data) {

                                console.log('err', data);
                                cancelBtn.click();
                            }
                        };

                        form.ajaxSubmit(options);
                    });
                });
            }
        };

        content.off('click', '.edit-employee').on('click', '.edit-employee', _editEmpFn);
    }

    function _validateInputs(popup) {

        var allValid = true;

        var nameInput     = popup.find('input[name="name"]');
        var usernameInput = popup.find('input[name="kurzname"]');
        var emailInput    = popup.find('input[name="email"]');
        var roleSelect    = popup.find('select[name="usergroup"]');
        var tenantSelect  = popup.find('select[name="tenant_id"]');

        if (nameInput.val().length === 0) {
            allValid = false;

            console.log('name empty');
        } else if (usernameInput.val().length === 0) {
            allValid = false;

            console.log('username empty');
        } else if (emailInput.val().length === 0) {
            allValid = false;

            console.log('email empty');
        } else if (roleSelect.val().length === 0) {
            allValid = false;

            console.log('role empty');
        } else if (tenantSelect.val().length === 0 || tenantSelect.val() === "0") {
            allValid = false;

            console.log('tenant empty');
        }

        return allValid;
    }

    function _imageUpload(contentPopup, employee) {

        var uploadWrapper  = contentPopup.find('.fileupload-trigger');
        var removeBtn      = contentPopup.find('.fileupload-remove-item');
        var previewWrapper = contentPopup.find('.fileupload-preview');
        var inputFile      = contentPopup.find('input[name="image"]');
        var inputFileName  = contentPopup.find('input[name="image_name"]');

        var _uploadFn = function (e) {
            e.preventDefault();
            e.stopPropagation();

            console.log('upload btn');

            inputFile.click();
        };

        var _removeFn = function (e) {
            e.preventDefault();
            e.stopPropagation();

            console.log('remove btn');

            inputFile.val('').trigger('change');
            inputFileName.val('');
        };

        function _readURL(input, wrapper) {

            if (input.files && input.files[0]) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    inputFileName.val('image_new');

                    wrapper.css('background-image', 'url(' + e.target.result + ')');
                };

                reader.readAsDataURL(input.files[0]);
            }
        }

        var _changeUploadFileFn = function (e) {
            var elem  = $(this);
            var input = this;

            console.log('changed', elem.val());

            var fileUploadHolder = previewWrapper.find('.fileupload-image-holder');

            if (elem.val().length) {
                // we got an image
                uploadWrapper.addClass('hidden');
                previewWrapper.removeClass('hidden');

                _readURL(input, fileUploadHolder);
            } else {

                uploadWrapper.removeClass('hidden');
                previewWrapper.addClass('hidden');

                fileUploadHolder.css('background', '');
            }
        };

        inputFile.off('change').on('change', _changeUploadFileFn);

        uploadWrapper.off('click').on('click', _uploadFn);
        removeBtn.off('click').on('click', _removeFn);

        if (employee && employee.image && employee.image_raw) {

            uploadWrapper.addClass('hidden');
            previewWrapper.removeClass('hidden');

            var fileUploadHolder = previewWrapper.find('.fileupload-image-holder');
            fileUploadHolder.css('background-image', 'url(' + encodeURI(employee.image_raw) + ')');
        }
    }

    function _deleteEmployee() {

        var employeeTable = content.find('#employee_table');

        var _deleteEmpFn = function (e) {
            var elem       = $(this);

            var employeeId   = elem.attr('data-employee-id');
            var employeeName = elem.attr('data-employee-name');

            console.log('delete', employeeId);

            var popupContent = quickverleih.template.render('./includes/einstellung/templates/delete_employee_popup._', {
                employeeId  : employeeId,
                employeeName: employeeName
            });

            var popup = openPopup(popupContent, '500px');

            var deleteBtn = popup.find('.delete-employee-yes');
            var cancelBtn = popup.find('.delete-employee-no');

            deleteBtn.off('click').on('click', function () {

                quickverleih.employees.api.deleteEmployee(employeeId, function (result) {

                    console.log('DELETE', result);

                    var rowParent = elem.parents('tr');
                    var dataTable = employeeTable.DataTable();

                    if (rowParent && rowParent[0]) {

                        dataTable.row(rowParent[0]).remove().draw(false);
                    }

                    cancelBtn.click();
                });
            });
        };

        content.off('click', '.delete-employee').on('click', '.delete-employee', _deleteEmpFn);
    }

    return {
        init: init
    };
})();
