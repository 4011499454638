
// make image sorting, by 'alt' attribute
jQuery.extend( jQuery.fn.dataTableExt.oSort, {
	    
    "alt-string-pre": function ( a ) {
        return a.match(/alt="(.*?)"/)[1].toLowerCase();
    },
     
    "alt-string-asc": function( a, b ) {
        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
    },
 
    "alt-string-desc": function(a,b) {
        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
    },

    "number_eu-pre": function ( a ) {
    	var number = a.replace(/[,.]/g , "");
    	return (number) * 1;
   	},

   	"number_eu-asc": function ( a, b ) {
    	return ((a < b) ? -1 : ((a > b) ? 1 : 0));
   	},

   	"number_eu-desc": function ( a, b ) {
    	return ((a < b) ? 1 : ((a > b) ? -1 : 0));
   	},

   	"date_eu-pre": function ( a ) {
    	var ukDatea = a.split('.');
    	return (ukDatea[2] + ukDatea[1] + ukDatea[0]) * 1;
   	},

   "date_eu-asc": function ( a, b ) {
    	return ((a < b) ? -1 : ((a > b) ? 1 : 0));
   	},

   	"date_eu-desc": function ( a, b ) {
    	return ((a < b) ? 1 : ((a > b) ? -1 : 0));
   	}

});
	
var datatables = {
	initializeDatatable: function() {
	
		var sortingCustomDefinitions = [];
		var aoColumnsElementList = [];
		
		// loop all table headers and get the types for each one and set the correct sorting type
		$( ".datatables-table-view thead tr th" ).each(function( index ) {
			
			var aoColumnsElement = null;

			switch($( this ).data('fieldType')) {
				
				case 'image':					  
					sortingCustomDefinitions.push({ "sType": "alt-string", "aTargets": [ index ] });
					break;

				case 'date':					  
					sortingCustomDefinitions.push({ "sType": "date_eu", "aTargets": [ index ] });
					break;	

				case 'number':					  
					sortingCustomDefinitions.push({ "sType": "number_eu", "aTargets": [ index ] });
					break;		

				case 'non-sortable':
					aoColumnsElement = {'bSortable':false}
					break;		  	
			}
		
			aoColumnsElementList.push(aoColumnsElement);
		});

		$('.datatables-table-view').dataTable({

			"aoColumns": aoColumnsElementList,
			"aoColumnDefs": sortingCustomDefinitions,
			"bJQueryUI" : true,
			"sPaginationType" : "full_numbers",
			"iDisplayLength": 50,
			"bInfo": true,
			"aaSorting" : [[0, 'desc']],
			"showNEntries" : true,
			// german translation. for multi language - move to separate file
			"oLanguage": {
				"sProcessing":   "Bitte warten...",
					"sLengthMenu":   "_MENU_ Eintr&auml;ge anzeigen",
					"sZeroRecords":  "Keine Eintr&auml;ge vorhanden.",
					"sInfo":         "_START_ bis _END_ von _TOTAL_ Eintr&auml;gen",
					"sInfoEmpty":    "0 bis 0 von 0 Einträgen",
					"sInfoFiltered": "(gefiltert von _MAX_  Eintr&auml;gen)",
					"sInfoPostFix":  "",
					"sSearch":       "Suchen",
					"sUrl":          "",
					"oPaginate": {
						"sFirst":    "<< erste",
						"sPrevious": "< zur&uuml;ck",
						"sNext":     "n&auml;chste >",
						"sLast":     "letzte >>"
					}
			},
			fnInfoCallback: function() {

				// get the new width of the table rows so that we know how much to expand to take the new contetn
				$('.dataTables_wrapper .ui-widget-header').css('width', ((parseInt($('.datatables-table-view thead tr').width()))-11)+'px'); // we remove the 10 px`s because of the padding of this element
				
			}
		});
	}
}

// initialize datatables
$(document).ready(function() {
	datatables.initializeDatatable();
});

	

